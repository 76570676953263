import Isotope from "isotope-layout";
import Swiper from "swiper"

export const getCookie = (name) => {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
}

export const getParents = (elem) => {
    var parents = [];
    while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
        elem = elem.parentNode;
        parents.push(elem);
    }
    return parents;
}

export const addZeroBeforeNumber = (number) => {
    return (number > 9) ? number : `0${number}`
}

export const initializeSwiper = (item, params) => {
    const swiper_el = new Swiper(item, params);
    swiper_el.init();
    return swiper_el;
}

export const initializeIsotop = (item) => {
    let filter_menu = item.closest(".grid-wrapper").querySelector(".filter-menu");

    const grid = new Isotope(item, {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        percentPosition: true,
        stagger: 0,
        masonry: {
            columnWidth: '.grid-sizer',
        }
    })

    setTimeout(() => {
        grid.arrange({ filter: `*` })
    }, 1000);

    filter_menu && filter_menu.querySelectorAll("li").forEach((el) => {
        el.querySelector("span").addEventListener("click", function (e) {
            let element = e.target,
                filtered_text = element.getAttribute("data-filter");

            filter_menu.querySelectorAll("li").forEach((elm) => elm.classList.remove("active"))
            element.closest("li").classList.add("active");
            grid.arrange({ filter: filtered_text === "*" ? "*" : `.${filtered_text}` })
        })
    })

    return grid
}

export const InputField = (value) => {

    document.querySelectorAll(".quantity").forEach(item => {
        let num = value ? value : 0,
            el = item.querySelector(".qty-text"),
            plusBtn = item.querySelector(".qty-plus"),
            minusBtn = item.querySelector(".qty-minus");

        plusBtn.addEventListener("click", () => {
            num = num + 1;
            el.setAttribute("value", num);
        });

        minusBtn.addEventListener("click", () => {
            num = num > 0 ? num - 1 : 0;
            el.setAttribute("value", num)
        });

        el.setAttribute("value", num);
    })
}

export const sendEmail = async (data) => {
    const req = await fetch(`/send`, {
        method: 'post',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    }).then(res => res.json()).then(data => data)
    return await req
}

export const resetForm = (actions, recaptcha) => {
    if (actions) {
        actions.resetForm()
        actions.setStatus(true)
        actions.setSubmitting(false);
        setTimeout(() => actions.setStatus(false), 5000)
    }

    if (recaptcha) {
        recaptcha.current.reset();
        recaptcha.current.captcha.classList.remove("error");
    }
}

// Header Menu Position
export const SetHeaderMenuPos = () => {
    
    function setMegaMenuPos() {
        let menu = document.querySelectorAll(".megamenu");
        if (menu.length > 0) {
            
            menu.forEach(item => {
                let menuWidth = item.clientWidth,
                    menuLeftPos = item.getBoundingClientRect().left;

                if (menuLeftPos + menuWidth >= window.screen.width) {
                    item.style.left = `-${(menuLeftPos + menuWidth) - window.innerWidth + 25}px`;
                }
            })
        }
    }

    function setSimpleMenuPos() {
        let menu = document.querySelectorAll(".simple-dropdown-menu");
        
        if (typeof (menu) != 'undefined' && menu != null) {
            menu.forEach(item => {
                let pos = item.getBoundingClientRect().left,
                    posTop = item.getBoundingClientRect().top,
                    width = item.clientWidth,
                    height = item.clientHeight;

                if (pos + width + 10 > window.innerWidth) {
                    item.style.left = `${window.innerWidth - (pos + width + 10)}px`;
                    item.classList.add("menu-left");
                }

                if ((posTop + height) > window.innerHeight) {
                    item.style.transform = `translateY(${-(posTop + height - window.innerHeight)}px)`;
                }
            })
        }
    }

    setMegaMenuPos();
    setSimpleMenuPos();

    window.addEventListener('resize', (event) => {
        setTimeout(() => {
            setMegaMenuPos();
            setSimpleMenuPos();
        }, 500);
    });
}

export const setDocumentFullHeight = () => {
    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--viewport-height', `${window.innerHeight}px`)
    }

    window.addEventListener("resize", documentHeight)
    documentHeight()
}

export const getPlanData = (tipo, plano) => {
    const annualPrices = {
        start: 66.9,
        light: 97,
        pro: 147,
      };
    
      const monthlyPrices = {
        start: 89,
        light: 129,
        pro: 196,
      };
    
      // Verifique o tipo (year ou month) e selecione os preços correspondentes
      const prices = tipo === 'year' ? annualPrices : monthlyPrices;

      switch (plano) {
        case 'start':
          return {
            name: 'Start',
            price: prices.start,
            total: tipo === 'year' ? prices.start * 12 : prices.start,
          };
        case 'light':
          return {
            name: 'Light',
            price: prices.light,
            total: tipo === 'year' ? prices.light * 12 : prices.light,
          };
        case 'pro':
          return {
            name: 'Pro',
            price: prices.pro,
            total: tipo === 'year' ? prices.pro * 12 : prices.pro,
          };
        default:
          return null; // Retorna null se o plano não for reconhecido
      }
}

export const formatCurrency = (price) => {
    const options = {
      style: 'currency',
      currency: 'BRL',
    }

    return price.toLocaleString('pt-BR', options)
}

export const firstUppercase = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const arrayPagination = (array, n) => {
    const pageSize = Math.ceil(array.length / n);
    return Array.from({ length: pageSize }, (_, index) => {
        const start = index * n;
        return array.slice(start, start + n);
    });
}

export const validaEmail = (email) => {
    if(email.indexOf('@') !== -1 && email.indexOf('.') !== -1) {
        return true
    }

    return false
}

export const validaCnpj = (cnpj) => {
    let cnpjNumerico = cnpj.replace(/[^\d]+/g, '');

    if (cnpjNumerico.length !== 14) {
        return false;
    }

    // Elimina CNPJs inválidos conhecidos
    if (
        cnpjNumerico === '00000000000000' ||
        cnpjNumerico === '11111111111111' ||
        cnpjNumerico === '22222222222222' ||
        cnpjNumerico === '33333333333333' ||
        cnpjNumerico === '44444444444444' ||
        cnpjNumerico === '55555555555555' ||
        cnpjNumerico === '66666666666666' ||
        cnpjNumerico === '77777777777777' ||
        cnpjNumerico === '88888888888888' ||
        cnpjNumerico === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpjNumerico.length - 2;
    let numeros = cnpjNumerico.substring(0, tamanho);
    let digitos = cnpjNumerico.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== parseInt(digitos.charAt(0), 10)) {
        return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpjNumerico.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== parseInt(digitos.charAt(1), 10)) {
        return false;
    }

    return true
}

export const getPathApi = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT
    const path = environment !== 'production' 
                ?
                    'http://localhost/GDPLACE/GDPlace' 
                : 
                    'https://sistema.gdplace.com.br'

    return path
}