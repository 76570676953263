const FooterData = [
    {
        title: "Empresa",
        submenu: [
            {
                title: "Sobre Nós",
                link: "/"
            },
            {
                title: "Blog",
                link: "/blog"
            },
            {
                title: "Planos",
                link: "/integrador"
            }
        ]
    },
    {
        title: "Outros",
        submenu: [
            {
                title: "Política de Privacidade",
                link: "/politica-privacidade"
            },
            {
                title: "Contato",
                link: "/contato"
            },
            {
                title: "Cadastro",
                link: "/cadastro"
            },
        ]
    },
]

export default FooterData