import Blockquote from "../Blockquote/Blockquote"
import Lists from "../Lists/Lists"
import { fadeIn } from "../../Functions/GlobalAnimations"
import { CreateSlug } from "../../Functions/CreateSlug"

export const blogData = [
    {
        id: 1,
        category: ["Web design", "Concept"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 1,
        likes: 28,
        comments: 0,
        date: "18 February 2020"
    },
    {
        id: 2,
        category: ["CONCEPT", "Web design"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 2,
        likes: 30,
        comments: 0,
        date: "09 January 2020"
    },
    {
        id: 3,
        category: ["NATURE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "People ignore designs that ignore people",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 23,
        comments: 0,
        date: "12 December 2020"
    },
    {
        id: 4,
        category: ["MODERN"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Look at usual things with unusual eyes",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 4,
        likes: 35,
        comments: 0,
        date: "23 November 2020"
    },
    {
        id: 5,
        category: ["FASHION"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Fashion is not something exists in dresses",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 5,
        likes: 25,
        comments: 0,
        date: "12 January 2020"
    },
    {
        id: 6,
        category: ["Lifestyle"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 6,
        likes: 35,
        comments: 0,
        date: "05 November 2020"
    },
    {
        id: 7,
        category: ["FURNITURE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Houseful of new furniture doesn't mean lot",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 7,
        likes: 20,
        comments: 0,
        date: "19 October 2020"
    },
    {
        id: 8,
        category: ["BRANDING"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "The best comfort food will always be greens",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 8,
        likes: 28,
        comments: 0,
        date: "09 September 2020"
    },
    {
        id: 9,
        category: ["TRAVELLING"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "I love traveling all over the world but it's true",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 9,
        likes: 25,
        comments: 0,
        date: "18 August 2020"
    },
    {
        id: 10,
        category: ["CREATIVE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "A true photograph not be explained, words",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 28,
        comments: 0,
        date: "26 June 2020"
    },
    {
        id: 11,
        category: ["BUSINESS"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Consider every mistake do make as asset",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 9,
        likes: 35,
        comments: 0,
        date: "20 April 2020"
    },
    {
        id: 12,
        category: ["EVENTS"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 5,
        likes: 22,
        comments: 0,
        date: "14 March 2020"
    },
    {
        id: 13,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 14,
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FASHION"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 2,
        likes: 25,
        comments: 0,
        date: "17 June 2020",
    },
    {
        id: 15,
        img: "https://via.placeholder.com/800x560",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CREATIVE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 16,
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["MODERN", "BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 17,
        img: "https://via.placeholder.com/800x560",
        title: "Reason and judgment are the qualities of leader",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CORPORATE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 5,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 18,
        img: "https://via.placeholder.com/800x560",
        title: "Business is combination of war and sport",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FINANCE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 6,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 19,
        img: "https://via.placeholder.com/800x560",
        title: "Look at usual things with unusual for eyes",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 7,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 20,
        img: "https://via.placeholder.com/800x560",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CONCEPT"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 8,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 21,
        img: "https://via.placeholder.com/800x560",
        title: "Good work for people good design is a language",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FURNITURE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 9,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 22,
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is only obscure your reference",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 23,
        img: "https://via.placeholder.com/800x560",
        title: "All progress takes place for outside comfort zone",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["DIGITAL"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 25,
        img: "https://via.placeholder.com/800x560",
        title: "Everything is designed for things are designed",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CREATIVE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 26,
        img: "https://via.placeholder.com/800x560",
        title: "Fashion and interior design are one the",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["Web Design"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 27,
        img: "https://via.placeholder.com/800x560",
        title: "The best comfort food will always be greens",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["SUMMER"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 5,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 28,
        img: "https://via.placeholder.com/800x560",
        title: "Get in over your head as often an possible",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 6,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 29,
        category: ["CREATIVE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 9,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 30,
        category: ["CONCEPT"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Simplicity, wit, and good typography",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 31,
        category: ["BUSINESS"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Look at usual things with unusual",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 32,
        category: ["LIFESTYLE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Do not seek praise seek criticism",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 33,
        category: ["MODERN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Make it simple, but significant",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 34,
        category: ["FURNITURE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Leave it better than you found it",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 35,
        category: ["DESIGN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Everything is design are designed well",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 36,
        category: ["FASHION"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Fashion fades, only style remains same",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 37,
        category: ["MODERN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Never give in except convictions sense",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 38,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 39,
        img: "https://via.placeholder.com/800x850",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 2,
        category: ["LIFESTYLE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 40,
        img: "https://via.placeholder.com/800x580",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 41,
        img: "https://via.placeholder.com/800x850",
        title: "Opportunities don't happen. You create them",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 4,
        category: ["TRAVEL"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 18,
        comments: 62,
        date: "15 December 2019",
    },
    {
        id: 42,
        img: "https://via.placeholder.com/800x850",
        title: "Never give in except to convictions good sense",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 5,
        category: ["MODERN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 22,
        comments: 38,
        date: "12 November 2019",
    },
    {
        id: 43,
        img: "https://via.placeholder.com/800x850",
        title: "An economist's guess is liable good as anybody",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 6,
        category: ["FOOD"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 12,
        comments: 52,
        date: "18 January 2020",
    },
    {
        id: 44,
        img: "https://via.placeholder.com/800x850",
        title: "All progress takes place outside the comfort zone",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 7,
        category: ["EVENTS"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "22 November 2019",
    },
    {
        id: 45,
        img: "https://via.placeholder.com/800x850",
        title: "Reason and judgment are the qualities of a leader",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 8,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 25,
        comments: 45,
        date: "16 October 2019",
    },
    {
        id: 46,
        img: "https://via.placeholder.com/800x850",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 9,
        category: ["FURNITURE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 50,
        date: "20 September 2019",
    },
    {
        id: 47,
        img: "https://via.placeholder.com/800x850",
        title: "People ignore designs that ignore people",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 32,
        comments: 42,
        date: "26 August 2019",
    },
    {
        id: 48,
        img: "https://via.placeholder.com/800x580",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 2,
        category: ["CONCEPT"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 36,
        comments: 52,
        date: "20 September 2019",
    },
    {
        id: 49,
        img: "https://via.placeholder.com/800x580",
        title: "A true photograph not be explained, words",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["MODERN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "20 June 2019",
    },
    {
        id: 50,
        category: ["CREATIVE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Recognizing the need the primary condition design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "18 MARCH 2020",

    },
    {
        id: 51,
        category: ["BUSINESS"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Good design is obvious great design transparent",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "25 FEBRUARY 2020",

    },
    {
        id: 52,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Any product that needs manual to work is broken",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "30 JANUARY 2020",

    },
    {
        id: 53,
        category: ["LIFESTYLE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Everything is designed for things are the designed",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "23 DECEMBER 2019",

    },
    {
        id: 54,
        category: ["FURNITURE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Houseful of new furniture doesn't mean lot modern",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "20 OCTOBER 2019",

    },
    {
        id: 55,
        category: ["FINANCE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Consider every mistake for you do make as an asset",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",

    },
    {
        id: 56,
        category: ["ARCHITECTURE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/940x940",
        title: "Architects design houses. I live in a home",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 MARCH 2020",
        double_col: true
    },
    {
        id: 57,
        category: ["CREATIVITY"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Good design doesn't date bad design does",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 FEBRUARY 2020",
        double_col: false
    },
    {
        id: 58,
        category: ["MODERN"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Bad design is smoke, while good design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "20 JANUARY 2020",
        double_col: false
    },
    {
        id: 59,
        category: ["BUSINESS"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/940x940",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 DECEMBER 2019",
        double_col: true
    },
    {
        id: 60,
        category: ["LIFESTYLE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",
        double_col: false
    },
    {
        id: 61,
        category: ["FASHION"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Consider every mistake you do make as asset",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "12 OCTOBER 2019",
        double_col: false
    },
    {
        id: 62,
        category: ["CONCEPT"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 SEPTEMBER 2019",
        double_col: false
    },
    {
        id: 63,
        category: ["DESIGN"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 AUGUST 2019",
        double_col: false
    },
    {
        id: 64,
        category: ["LIFESTYLE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUNE 2019",
        double_col: false
    },
    {
        id: 65,
        category: ["CREATIVITY"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Reason and judgment are the qualities of a leader",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 APRIL 2019",
        double_col: false
    },
    {
        id: 66,
        category: ["fashion"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Build perfect websites",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 FEBRUARY 2020",

    },
    {
        id: 67,
        category: ["travel"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Beautiful layouts design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",

    },
    {
        id: 68,
        category: ["fashion", "travel"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Online website builder",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 JANUARY 2020",

    },
    {
        id: 69,
        category: ["travel", "Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Different layout type",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 DECEMBER 2019",

    },
    {
        id: 70,
        category: ["fashion"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Elegant elements design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",

    },
    {
        id: 71,
        category: ["creative"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "The best comfort music",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 OCTOBER 2019",

    },
    {
        id: 72,
        category: ["fashion", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Consider every mistake",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 SEPTEMBER 2019",

    },
    {
        id: 73,
        category: ["travel", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Creativity is only obscure",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 AUGUST 2019",

    },
    {
        id: 74,
        category: ["Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "The best way to predict",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "27 APRIL 2019",

    },
    {
        id: 75,
        category: ["Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Never give in except",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 MARCH 2019",

    },
    {
        id: 76,
        category: ["Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "An economist's guess",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "09 FEBRUARY 2019",

    },
    {
        id: 77,
        category: ["Lifestyle", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Look at usual things",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 JANUARY 2019",

    },
    {
        id: 78,
        category: ["CREATIVE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        title: "Tools i use to learn, work and travel...",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        comments: 0,
        date: "11 February 2019"
    },
    {
        id: 79,
        category: ["CONCEPT"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Without good architecture design it’s easy...",
        author: 3,
        comments: 0,
        date: "15 February 2019"
    },
    {
        id: 80,
        category: ["EVENTS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Everybody can be great because...",
        author: 3,
        comments: 0,
        date: "25 February 2019"
    },
    {
        id: 81,
        category: ["LIFESTYLE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Styles come and go design is simple...",
        author: 3,
        comments: 0,
        date: "23 January 2019"
    },
    {
        id: 82,
        category: ["NATURE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Alone we can do so little together...",
        author: 3,
        comments: 0,
        date: "19 December 2019"
    },
    {
        id: 83,
        category: ["MOUNTAINS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Design is inherently best optimistic power...",
        author: 3,
        comments: 0,
        date: "18 January 2019"
    },
    {
        id: 84,
        category: ["FINANCE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "People ignore design that ignores...",
        author: 3,
        comments: 0,
        date: "11 April 2019"
    },
    {
        id: 85,
        category: ["BUSINESS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "A true photograph not be explained...",
        author: 3,
        comments: 0,
        date: "25 March 2019"
    },
    {
        id: 86,
        category: ["LIFESTYLE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Everything creative design things...",
        author: 3,
        comments: 0,
        date: "19 June 2019"
    },
    {
        id: 87,
        category: ["FASHION"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Consider every mistake do make...",
        author: 3,
        comments: 0,
        date: "30 August 2019"
    },
    {
        id: 88,
        category: ["FURNITURE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Simplicity is the ultimate sophistic...",
        author: 3,
        comments: 0,
        date: "12 June 2019"
    },
    {
        id: 89,
        category: ["MODERN"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "The best comfort food will always...",
        author: 3,
        comments: 0,
        date: "18 January 2019"
    },
    {
        id: 90,
        category: ["CREATIVE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "DESIGN CAN SPEAK THE IN TONGUE OF ART FORCE OF COMMERCE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 MAY 2020",

    },
    {
        id: 91,
        category: ["CONCEPT"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "SIMPLICITY IS PROCESS THE OBVIOUS AND ADDING MEANINGFUL",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUN 2020",

    },
    {
        id: 92,
        category: ["MODERN"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "EVERYTHING KNOW ABOUT MIDI SKIRTS AND HOW TO WEAR FASHION",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 AUG 2020",

    },
    {
        id: 93,
        category: ["FURNITURE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "RECOGNIZING THE NEED IS THE PRIMARY CONDITION FOR DESIGN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 APR 2020",

    },
    {
        id: 94,
        category: ["FASHION"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "FASHION IS SOMETHING BARBAROUS ON FOR IT INNOVATION",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 MAR 2020",

    },
    {
        id: 95,
        category: ["LIFESTYLE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "RAPPERS WEAR DIAMONDS TO COMPENSATE FOR THE FASHION SENSE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEB 2020",

    },
    {
        id: 96,
        category: ["BUSINESS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "THE DETAILS ARE NOT THE DETAILS. THEY MAKE THE BEST DESIGN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 JAN 2020",

    },
    {
        id: 97,
        category: ["FINANCE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Creativity is only as obscure as the your  reference",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "26 DEC 2019",

    },
    {
        id: 98,
        category: ["MOUNTAINS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "ANY PRODUCT THAT NEEDS A MANUAL TO HARD WORK IS BROKEN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "09 NOV 2019",

    },
    {
        id: 99,
        category: ["NATURE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "REASON AND JUDGMENT ARE THE QUALITIES OF A GOOD LEADER",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 OCT 2019",

    },
    {
        id: 100,
        category: ["CREATIVE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "GOOD DESIGN IS OBVIOUS. GREAT DESIGN IS MORE TRANSPARENT",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "16 OCT 2019",

    },
    {
        id: 101,
        category: ["EVENTS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "ALL PROGRESS TAKES PLACE OUTSIDE OF THE COMFORT ZONE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "25 SEP 2019",

    },
    {
        id: 102,
        category: ["LIFESTYLE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "How to build branded photography studios",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 MAY 2020",

    },
    {
        id: 103,
        category: ["FASHION"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Many things difficult to design prove easy",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 APRIL 2020",

    },
    {
        id: 104,
        category: ["CREATIVE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "The proper study of the science of design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 MARCH 2020",

    },
    {
        id: 105,
        category: ["MODERN"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 FEBRUARY 2020",

    },
    {
        id: 106,
        category: ["CORPORATE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Leave it better than you more found it",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 JANUARY 2020",

    },
    {
        id: 107,
        category: ["FINANCE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Do one thing a every day that scares you",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "24 DECEMBER 2019",

    },
    {
        id: 108,
        category: ["BUSINESS"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "A company is only as good as the people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "16 NOVEMBER 2019",

    },
    {
        id: 109,
        category: ["CONCEPT"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Do not seek praise. Seek the criticism",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 OCTOBER 2019",

    },
    {
        id: 110,
        category: ["FURNITURE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "If you do it right, it will the last forever",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "08 SEPTEMBER 2019",

    },
    {
        id: 111,
        category: ["LIFESTYLE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Judgment are the an qualities of leader",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 APRIL 2019",

    },
    {
        id: 112,
        category: ["DIGITAL"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Simplicity, cognition, and good typography",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "29 AUGUST 2019",

    },
    {
        id: 113,
        category: ["CREATIVE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Everything should be made as simple",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 OCT 2019",

    },
    {
        id: 114,
        category: ["Web Design", "drink"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://lithoreact.themezaa.com/assets/img/webp/blog-img-33.webp",
        title: "Make business easy with beautiful application download store",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 115,
        category: ["Web Design"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://lithoreact.themezaa.com/assets/img/webp/blog-img-33.webp",
        title: "Change your life today. Don't gamble on the future, act now, without delay.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 116,
        category: ["Web Design", "summer"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://lithoreact.themezaa.com/assets/img/webp/blog-img-33.webp",
        title: "The time to be happy is now, and the place to be happy is here.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 117,
        category: ["Web Design", "coffee"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://lithoreact.themezaa.com/assets/img/webp/blog-img-33.webp",
        title: "Whenever I think of the past, it brings back so many memories.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 118,
        category: ["Architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Architects design houses. I live in a home",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "05 March 2020",
    },
    {
        id: 119,
        category: ["Creativity"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Good design doesn't date bad design does",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "28 February 2020",
    },
    {
        id: 120,
        category: ["Garnett"],
        title: "Fashion and interior design are same",
        img: "https://via.placeholder.com/250x250",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 121,
        category: ["Garnett"],
        title: "Everything designed things are designed",
        img: "/assets/img/webp/spa-image-05.webp",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 122,
        category: ["Magistre"],
        title: "Consider every mistake do make",
        img: "https://via.placeholder.com/250x250",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 2,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 123,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 124,
        img: "https://via.placeholder.com/800x580",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE", "BUSINESS"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 125,
        img: "https://via.placeholder.com/720x522",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 126,
        img: "https://via.placeholder.com/720x522",
        title: "Computers are to design as microwaves are to cooking ",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 126,
        img: "https://via.placeholder.com/720x522",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["DESIGN", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 127,
        img: "https://via.placeholder.com/526x368",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 128,
        img: "https://via.placeholder.com/526x368",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 129,
        img: "https://via.placeholder.com/526x368",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 130,
        img: "https://via.placeholder.com/526x368",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 131,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "Incorporating newest trends in digital marketing",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 FEBRUARY 2020",
    },
    {
        id: 132,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "Corporate workplace ethics that makes a difference",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "20 FEBRUARY 2020",
    },
    {
        id: 133,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "We take pride in delivering Intelligent and engaging",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",
    },
    {
        id: 134,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 134,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 135,
        img: "https://via.placeholder.com/800x560",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 136,
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 137,
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 138,
        category: ["Creativity", "architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Bad design is smoke, while good design is a mirror",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 139,
        category: ["Design", "architecture", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 140,
        category: ["Architecture", "architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 141,
        category: ["Interior", "architecture", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Reason and judgment are the qualities of a leader",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 142,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 143,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Tour packages are the easiest and most efficient",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 143,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 144,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 145,
        img: "https://via.placeholder.com/800x560",
        title: "Simple wall decor inspiration saturdays style",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["FURNITURE", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "24 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 146,
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DECOR", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "10 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 147,
        img: "https://via.placeholder.com/800x560",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "18 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 148,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["FASHION", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "24 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 149,
        img: "https://via.placeholder.com/800x580",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["LIFESTYLE", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 40,
        date: "10 February 2020",
        link: "/blogs/blog-post-layout-02",
    },
    {
        id: 150,
        img: "https://via.placeholder.com/800x580",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 40,
        date: "18 January 2020",
        link: "/blogs/blog-post-layout-03",
    },
    {
        id: 151,
        category: ["Creativity", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Architects design houses. I live in a home",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 152,
        category: ["Modern", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Good design doesn't date. Bad design does",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 153,
        category: ["CONCEPT", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 SEPTEMBER 2019",
        double_col: false
    },
    {
        id: 154,
        category: ["DESIGN", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 AUGUST 2019",
        double_col: false
    },
    {
        id: 155,
        category: ["LIFESTYLE", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUNE 2019",
        double_col: false
    },
    {
        id: 156,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Build perfect websites",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 FEBRUARY 2020",
    },
    {
        id: 157,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Beautiful layouts design",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",
    },
    {
        id: 158,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Online website builder",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 JANUARY 2020",
    },
    {
        id: 159,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Different layout type",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 DECEMBER 2019",
    },
    {
        id: 160,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Elegant elements design",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",
    },
    {
        id: 161,
        category: ["Architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Architects design houses. I live in a home",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 March 2020",
        double_col: false
    },
    {
        id: 162,
        category: ["Creativity"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Good design doesn't date bad design does",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 February 2020",
        double_col: false
    },
]


export const BlogCategoryData = [
    {
        id: 118,
        category: "LIFESTYLE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 119,
        category: "SUMMER",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 120,
        category: "COFFEE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 121,
        category: "FASHION",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 122,
        category: "DRINKS",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    }
]

export const authorData = [
    {
        id: 1,
        img: "https://via.placeholder.com/125x125",
        name: "Torrie asai",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 2,
        img: "https://via.placeholder.com/125x125",
        name: "Walton smith",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 3,
        img: "https://via.placeholder.com/125x125",
        name: "Equipe GDPlace",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 4,
        img: "https://via.placeholder.com/125x125",
        name: "Vico magistre",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 5,
        img: "https://via.placeholder.com/125x125",
        name: "Coco chanel",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 6,
        img: "https://via.placeholder.com/125x125",
        name: "Mark lamb",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 7,
        img: "https://via.placeholder.com/125x125",
        name: "Lindsey bucki",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 8,
        img: "https://via.placeholder.com/125x125",
        name: "Maya angelou",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 9,
        img: "https://via.placeholder.com/125x125",
        name: "Jeremy dupont",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
]

export const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "FASHION",
        key: "fashion "
    },
    {
        title: "TRAVEL",
        key: "travel "
    },
    {
        title: "LIFESTYLE",
        key: "lifestyle "
    },
    {
        title: "BUSINESS",
        key: "business"
    }
]

const post1Data = () => {
    return (
        <>
            <p>
                O Sistema Comparador de preços GDPlace oferece <b>diversas funcionalidades</b>, além de fazer o<b>
                comparativo de kits solares entre diversos distribuidores do segmento.</b>
            </p>

            <p>
                Antes do projeto GDPlace ser desenvolvido, éramos uma empresa integradora. Portanto, sentimos
                na pele diversos dificuldades comuns entre empresas do ramo. Essa experiência de 8 anos,
                nos proporcionou conhecimento e autonomia para criar um novo modelo de negócio, capaz de
                suprir a falta de uma ferramenta facilitadora.
            </p>

            <p>
                Era uma grande dificuldade para os integradores a busca por kits solares adequados, assim como
                o dimensionamento de sistemas, acompanhamento do desempenho da equipe, geração das propostas,
                entre outros problemas. O maior, que realmente sempre chamou atenção, é o tempo perdido cotando
                com várias empresas, uma por vez. Comparar o melhor custo benefício, parecia uma missão
                impossível.
            </p>

            <p>
                Levando em consideração os dados da última pesquisa do mercado brasileiro de TI feita pela
                Fundação Getúlio Vargas (FGV), que mostra que <b>95% das empresas afirmam que já iniciaram o
                processo de transformação digital</b>, entre elas, 46% estão em uma fase intermediária, onde
                novas tecnologias começam a mudar a maneira como o negócio é gerido e diante disso e com uma
                visão empreendedora de mercado, a GDPlace saiu do papel.
            </p>

            <p>
                <b>O objetivo do Sistema GDPlace é adicionar tecnologia e modernizar a rotina de trabalho do
                integrador,</b> sendo um facilitador, atendendo as necessidades de profissionais da área de
                energia solar em larga escala e melhorando a forma de gestão de tempo e processos.
            </p>

            <p>
                Com a GDPlace, integradores podem <b>dimensionar sistemas</b>, visualizar as <b>melhores opções
                de preços</b>, <b>gerenciar orçamentos </b>de uma forma fácil, emitir, atualizar e enviar 
                <b> propostas personalizadas </b>com dados e logo, <b>acompanhar os pedidos</b> desde a compra
                até a entrega do produto, fazer a<b> gestão das vendas</b>, <b>monitorar sua equipe comercial </b>
                e<b> visualizar todos os resultados</b> da sua empresa, de uma forma extremamente dinâmica e
                intuitiva, além disso, oferece <b>promoções diárias exclusivas</b>!
            </p>

            <p>
                Todas estas funcionalidades e benefícios do Sistema Comparador de Preços GDPlace são totalmente
                gratuitas. Ou seja, <b>os integradores&nbsp;tem acesso a um sistema que tem tudo que precisam 
                para fechar mais negócios 100% grátis.</b> Além de poder contar com uma equipe de suporte 
                especializada para solucionar dificuldades e atender dúvidas que possam surgir ao logo da
                experiência GDPlace.
            </p>

            <p>
                Uma inspiradora fala de Bill Gates simplifica o objetivo da criação da 
                GDPlace:<b> "A maneira que você coleta, gerencia e utiliza as informações, determina se você
                vai vencer ou perder".</b> Deste modo, o sistema surge com a promessa de transformar definitivamente
                a gestão dos trabalhos com energia solar, para<b> facilitar processos</b> e, consequentemente,
                &nbsp;<b>aumentar resultados.</b>
            </p>
        </>
    )
}

const post2Data = () => {
    return (
        <>
            <p>
                O Sistema Comparador de Preços GDPLACE é uma <b>solução totalmente programada para atender integradores em
                larga escala.</b> Uma ferramenta que faz comparativo de preços integrado com vários distribuidores de 
                kits de energia solar.
            </p>

            <p>
                O maior objetivo da GDPlace é <b>facilitar o relacionamento e comercialização de geradores de energia
                solar </b>para integradores e distribuidores do segmento, tornando-se a maior aliada na rotina de
                trabalho com energia solar, reduzindo custos e potencializando resultados.
            </p>

            <p>
                Por trás de um sistema, existe uma equipe especializada e competente que conhece as dificuldades dos
                integradores. Com base nisso, criou esse <b>novo jeito de fazer negócios, muito mais rápido, 
                confiável e sempre trazendo as melhores opções de preços de kits fotovoltaicos.</b>
                As empresas cadastradas foram atraídas pela <b>facilidade em montar orçamentos, otimização de tempo,
                agilidade de emitir propostas personalizadas e pela certeza de ter realizado a melhor compra.</b>
            </p>

            <p>
                O novo modelo de negócio está vindo em um momento bastante propício, o Brasil subiu cinco
                posições no<b> ranking global de países líderes em projetos de energia solar fotovoltaica e está
                em 16º lugar</b> entre 193 países. Segundo informações da Associação Brasileira de Energia Solar
                Fotovoltaica, o crescimento representou expansão de 90% no setor. Em 2019, por exemplo, 
                a área de energia solar trouxe ao Brasil 10,7 bilhões de reais em novos investimentos e mais de 63
                mil empregos, mostrando que o setor permanece em crescimento e agora pode contar com um Sistema que
                será um grande aliado.
            </p>
        </>
    )
}

const post3Data = () => {
    return (
        <>
            <p>
                <b>“Simplifique suas vendas”</b>, essa é a essência da GDPLACE, que chegou para <b>conectar integradores
                e distribuidores</b> de sistemas fotovoltaicos, por meio de um sistema comparador de preços integrado com
                diversas funcionalidades.
            </p>

            <p>
                A alta procura por um bom sistema, capaz de reunir distribuidores e integradores do setor solar, despertou 
                a atenção de quatro empreendedores do ramo em meados de 2018, que começaram a debater a criação de uma solução para
                atender integradores em larga escala, oferecendo um comparativo de preços totalmente integrado com distribuidores de
                equipamentos fotovoltaicos.
            </p>

            <p>
                Em 2019 a ideia começou a sair do papel na cidade de Tijucas, SC e a programação do
                maior e mais inovador comparador de preços integrado do setor solar começou a se concretizar. Apesar da
                grandiosidade do projeto, após 18 meses de árduo trabalho, a GDPLACE apresenta oficialmente ao mercado seu
                sistema, que promete ser o<b> grande aliado das empresas integradoras do setor solar.</b>
            </p>

            <p>
                O sistema chega em boa hora. Mesmo em meio aos impactos da pandemia no mundo, no
                primeiro semestre deste ano, <b>o Brasil entrou para o seleto grupo de 20 países líderes em projetos de
                    energia solar fotovoltaica no mundo</b> (Fonte: ABsolar), mostrando que o segmento está em ascensão e
                agora contará com um grande aliado.
            </p>

            <p>
                Este, certamente é um marco histórico para o setor, o início de <b>um novo jeito
                    de fazer negócios, muito mais rápido, confiável e sempre trazendo as melhores opções de preços</b>
                entre diversos distribuidores do segmento para as empresas integradoras cadastradas no sistema.
            </p>
        </>
    )
}

const post4Data = () => {
    return (
        <>
            <p>
                Após o anúncio de que o <b>Sistema Comparador de Preços será totalmente gratuito</b>, a GDPlace 
                inova mais uma vez com a inclusão de <b>novas funcionalidades no Sistema</b>, que tornarão 
                a<b> experiência</b> dos usuários ainda melhor, mais <b>dinâmica </b>e<b> rápida.</b>
            </p>

            <div className="d-flex justify-center my-6">
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/conteudo%20imagem.png" width="600" />
                </a>
            </div>

            <p>
                <b>As novidades anunciadas para o Sistema Comparador de Preços GDPlace consistem em 
                melhorias na ferramenta de “Propostas Personalizada”, no desenvolvimento de um 
                “Comparador Técnico” dentro do sistema, na inclusão do “Solar Edge” e de marcas de 
                Micro Inversores.</b>
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6 w-[80%]"
                    theme="blockquote-style-02"
                    title="Funcionalidades e parcerias que trarão para o Integrador, maior flexibilização na emissão
                    de propostas, uma maior segurança na escolha do seu kit e também uma gama maior de opções com a
                    inclusão do Solar Edge e dos Micro inversores."
                    author="Arnaldo Junior, Sócio Proprietário e Gerente de Processos"
                />
            </div>

            <p className="mt-3">
                <h6 className="font-serif text-darkgray font-medium">Sobre as funcionalidades:</h6>
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Melhoria na Proposta Personalizada
                </h7>
            </div>

            <p>
                O objetivo do aprimoramento desta funcionalidade que já está dentro do Sistema, é 
                flexibilizar ainda mais a edição das propostas e acrescentar novos modelos de layout, 
                como maiores opções de capas e modelos internos. Além de permitir também que o próprio
                Integrador crie seu próprio modelo de proposta dentro do sistema.
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6 w-[80%]"
                    theme="blockquote-style-02"
                    title="Sentimos que essa funcionalidade era necessária para que o Integrador sinta que o
                    sistema possui um ambiente amigável, de fácil uso e que se adeque ao seu dia-a-dia."
                    author="Arnaldo Junior, Sócio Proprietário e Gerente de Processos"
                />
            </div>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Comparador Técnico
                </h7>
            </div>


            <p>
                Além de comparar preços, surgiu a necessidade de oferecer também um Comparador Técnico
                entre os kits solares. Essa funcionalidade está em processo final de desenvolvimento 
                e visa oferecer maior segurança ao Integrador na escolha dos kits.
            </p>

            <p>
                Estão entre os indicadores de comparação técnica: modelo, marca, tempo de garantia,
                potência do equipamento, entre outras informações técnicas de cada tipo de equipamento.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Solar Edge e Micro inversor
                </h7>
            </div>

            <p>
                Em breve disponibilizaremos as tecnologias SolarEdge e Micro Inversores dentro do Sistema
                Comparador de Preço GDPlace. O objetivo é oferecer mais e melhores opções aos 
                Integradores, bem como permitir que possam atender os mais diversos tipos de público e 
                clientes.
            </p>
        </>
    )
}

const post5Data = () => {
    return (
        <>
            <p>
                A expansão de qualquer negócio representa um grande desafio na gestão e melhoria dos processos 
                internos, exigindo que as empresas se adequem às tecnologias e busquem soluções que otimizem o
                tempo e consequentemente, os resultados. Nessa hora, contar com um sistema que funcione como um
                aliado na execução das tarefas pode ser muito vantajoso!
            </p>

            <p>
                No setor solar não é diferente. Por este motivo, o <b>Sistema Comparador de Preços GDPlace</b>
                chegou para auxiliar o integrador, otimizando seus processos diários e <b>permitindo uma gestão
                muito mais eficiente do seu negócio</b>.
            </p>

            <p>
                Há diversos fatores fundamentais que devem ser levados em consideração no momento da escolha do
                sistema ideal, entre eles: experiência de mercado, se o sistema é especifico ou não para o tipo
                de negócio, investimento financeiro para adquirir o sistema e o principal: o suporte técnico que
                a empresa oferece após o início do uso ou implantação do sistema.
            </p>

            <div className="d-flex justify-center my-12">
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/Arte_cta%20blog.png" width="900" />
                </a>
            </div>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Mas por que o Suporte Técnico é tão importante?
                </h7>
            </div>

            <p>
                O principal objetivo do Suporte Técnico vai muito além de solucionar dúvidas ou problemas momentâneos
                dos usuários. Na prática, o Suporte Técnico tem o dever de transformar a experiência e a usabilidade
                do usuário dentro do sistema, na melhor possível.
            </p>

            <p>
                Funções como analisar o comportamento do usuário, para que o mesmo não perca o interesse,
                ou até mesmo antecipar algumas possíveis dificuldades, são essenciais na rotina do Suporte
                Técnico. É comum encontrar usuários insatisfeitos pela demora no retorno, mal atendimento ou
                ausência de solução para seus problemas, isso torna a experiência do usuário desagradável
                e frustrante.
            </p>

            <p>
                Por isso, um dos principais <b>diferenciais do Sistema GDPlace</b>, além de ser <b> 
                100% gratuito</b>, é dispor de um<b> Suporte Técnico especializado </b>e altamente 
                preparado para <b>auxiliar o Integrador </b>em qualquer possível dúvida que possa surgir 
                durante o uso no sistema.
            </p>

            <p>
                O contato é feito diretamente dentro do sistema através de chat, ou WhatsApp, de forma rápida
                e eficiente, solucionando problemas e esclarecendo dúvidas para que não haja interrupções 
                no trabalho do Integrador.
            </p>

            <p>
                Paralelo a isso, também monitoramos os usuários diariamente e auxiliamos quando o Analista do 
                Suporte julga necessário, antecipando dúvidas ou dificuldade no uso do sistema, mesmo que ele
                ofereça tutoriais de uso dinâmicos e intuitivos.
            </p>

            <p>
                Conheça na prática todos os<b> benefícios</b> que o <b>Sistema Comparador de Preços 
                GDPlace </b>pode oferecer.<b> Tenha a certeza de contar com uma equipe preparada e comprometida
                para auxiliar a aumentar seus resultados.</b>
            </p>
        </>
    )
}

const post6Data = () => {
    return (
        <>
            <p>
                Atualmente, administrar uma empresa requer, além de muita responsabilidade e coragem, um trabalho
                focado em gestão. Se o empreendedor pretende alavancar seu negócio, é preciso analisar o mercado,
                acompanhar o movimento da concorrência e estar sempre atualizado com informações relevantes do
                segmento.
            </p>

            <p>
                Contudo, ainda não é o suficiente. A gestão de uma empresa vai além da análise de fatores externos,
                é necessário também estar de olho em algumas métricas ou indicadores internos que, se analisados de
                forma correta, permitem ao gestor levar seu negócio ao sucesso.
            </p>

            <p>
                Mas a maior dúvida de um gestor é: quais indicadores da minha empresa eu devo acompanhar? 
                Como utilizá-los?
            </p>

            <p>
                Para uma empresa integradora, é importante analisar os seguintes indicadores:
            </p>

            <div className="d-flex justify-center my-12">
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/conteudo%20imagem.png" width="600" />
                </a>
            </div>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                        Taxa de conversão de vendas:
                </h7>
            </div>

            <p>
                Essa é uma métrica importante para mensurar a efetividade de vendas da equipe. O cálculo é
                feito de acordo com o nível de gestão ou a compreensão de taxa de conversão de cada empresa,
                ou seja, algumas empresas medem a taxa de acordo com a emissão de propostas e vendas
                efetivadas e outras, com um nível de prospecção mais estruturado, medem a taxa de conversão
                a partir das vendas efetivadas de acordo com os leads captados.
            </p>

            <p>
                É importante monitorar a taxa de conversão levando em consideração a estrutura da empresa e
                gerar analises como a assertividade da equipe comercial nas vendas. Estratégias podem ser
                aplicadas para otimizar a taxa de conversão, como por exemplo, levantar as objeções de
                compra, causas, motivos de rejeição e elaborar uma forma de apresentação mais eficaz, ou
                até mesmo, otimizar o processo de prospecção e captação de leads.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Ticket Médio:
                </h7>
            </div>

            <p>
                O Ticket médio é outro indicador importante para o segmento de energia solar, principalmente 
                se a empresa tiver uma equipe de vendas. O Ticket médio permite avaliar a receita média de
                vendas da empresa.
            </p>

            <p>
                A análise pode ser mais específica quando se avalia o ticket médio de cada vendedor da equipe, 
                desta forma, pode-se detectar qual é o tipo de cliente que cada vendedor costuma atender, ou seja,
                tendo como base o ticket médio dos vendedores, a equipe comercial poderá direcionar um cliente maior
                para o vendedor que possui um ticket médio maior e desta forma acontece o mesmo com um cliente menor e
                o vendedor que possui um ticket médio menor.
            </p>

            <p>
                Obviamente que com a análise de vendas e ticket médio, pode-se avaliar o desempenho de cada vendedor e 
                se atende o número de vendas estipulado pela empresa ou não. Desta forma, o gestor poderá tomar as
                medidas que achar necessário para otimizar o resultado da equipe.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Como organizar essas informações?
                </h7>
            </div>

            <p>
                Contudo, é extremamente importante reunir essas informações em um só lugar e no Sistema Comparador de Preços
                GDPlace, o Integrador consegue visualizar esses dois indicadores extremamente importantes para o negócio 
                de energia solar.
            </p>

            <p>
                Informações que antes eram deixadas de lado, ou estavam presas em uma planilha em excel engessada,
                cuja alimentação era feita manualmente, com grande possibilidade de erros, agora são automatizadas no
                Sistema Comparador de Preços GDPlace, de acordo com o número de propostas geradas, pedidos efetuados e
                o faturamento de cada pedido e cada vendedor dentro do Sistema.
            </p>

            <p>
                Um Sistema 100% gratuito e que tem tudo que o Integrador precisa para fechar mais negócios e otimizar
                a gestão do seu negócio.
            </p>

            <p>
                Cadastre-se e comece sua experiência GDPlace agora mesmo!
            </p>

            <p>
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <b>https://www.gdplace.com.br/cadastro/</b>
                </a>
            </p>
        </>
    )
}

const post7Data = () => {
    return (
        <>
            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    O mercado de negócios, independente do segmento, está cada vez mais acirrado. Novas e 
                    melhores oportunidades surgem à todo momento e a pergunta que ecoa na cabeça do gestor é:
                    como minha empresa pode se destacar no mercado?
                </h7>
            </div>

            <p>
                Há diversas variáveis que podem influenciar nessa resposta, fatores externos que implicam nos 
                resultados e fatores internos que o gestor consegue controlar, mensurar e tomar as decisões que
                considerar estratégicas.
            </p>

            <p>
                No segmento de energia solar e, mais especificamente para Integradores, uma das principais 
                dificuldades em se destacar no mercado, segundo dados de uma pesquisa da Greener, é a 
                concorrência acirrada para 26,3% das empresas. Outras 31,5% dos Integradores responderam os clientes
                consideram alto o custo para adquirir soluções fotovoltaicas.
            </p>

            <h7 className="font-serif text-darkgray font-medium mb-3">
                Desta forma, como driblar esses empecilhos e sair na frente da concorrência, 
                oferendo os melhores produto ou serviço ao seu cliente?
            </h7>

            <p>
                A verdade é que nenhuma empresa é vítima das circunstâncias, ou seja, destaca-se quem tem
                processos definidos e bem estruturados. Esses processos começam na organização da rotina de
                trabalho e uma das principais etapas é o processo de orçamentos, com a estruturação
                de um modelo de proposta padrão que atenda aos requisitos dos clientes e a definição do que
                é importante inserir ou não na proposta. Além disso, é preciso analisar indicadores de desempenho 
                da empresa e de vendedores e, não menos importante, a forma como seu cliente é atendido, desde
                a prospecção, até o pós-venda.
            </p>

            <p>
                Após uma estruturação básica desses processos, o gestor poderá focar em assuntos
                realmente relevantes e estratégicos, que vão desde o aumento do seu negócio, o
                estabelecimento de novas parcerias, ou qualquer outra ação estratégica que visa alavancar
                o negócio.
            </p>

            <p>
                Para muitos Integradores, este “feijão com arroz”, ainda é um desafio. Muitos, 
                quando foram em busca uma ferramenta que pudesse auxiliá-lo a otimizar seus processos, 
                se depararam com algumas dificuldade do tipo: encontrar soluções específicas, alto custo para
                contratar, ou mesmo a falta de garantias de que iria ter um retorno positivo.
            </p>

            <hr className="my-[24px]"></hr>

            <p>
                Pensando em todas as dificuldades do Integrador, surgiu o <b>Sistema Comparador de Preços 
                GDPlace</b>. Um<b> Sistema 100% gratuito</b>, que compara preços de kits solares fotovoltaicos
                entre diversos distribuidores, de forma fácil e ágil. O sistema gera orçamentos e propostas
                personalizadas, com os dados completos do cliente e do integrador. O envio destas propostas
                também é feito de forma automatizada, via e-mail e WhatsApp, diminuindo o tempo de retorno 
                e contato com o cliente.
            </p>

            <p>O Sistema GDPlace aponta indicadores de acordo com as ações executadas dentro do sistema, como
                por exemplo: ticket médio por vendedor, da empresa e taxa de conversão de propostas em pedidos.
                Estes indicadores são extremamente importantes para a tomada de decisões.</p>

            <p>
                <b>O Sistema já conta e auxilia mais de 1000 Integradores</b>, e não há dúvidas de que o
                Integrador que está em busca de uma ferramenta que otimize os processos da sua empresa e
                quer atender melhor seu cliente, oferecendo os melhores produtos com os melhores preços,
                e que de forma geral, consiga alavancar seu negócio consideravelmente, deverá usar o Sistema
                Comparador de Preços GDPlace.
            </p>

            <p>
                Cadastre-se, e use e seja mais um Integrador a alavancar o seu negócio com o Sistema 
                Comparador de Preços GDPlace.
            </p>

            <div className="d-flex justify-center my-12">
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/Arte_cta%20blog.png" width="850" />
                </a>
            </div>
        </>
    )
}

const post8Data = () => {
    return (
        <>
            <p>
                O mercado de energia solar está em constante expansão no cenário nacional. Segundo a ABSolar, o crescimento
                em 2019 atingiu 212% comparado ao ano anterior, alcançando a marca de 2,4GW instalados. Neste ano, entre 
                janeiro e maio e, em meio a uma pandemia, essa marca já atingiu aproximadamente&nbsp;1GW.
            </p>

            <p>
                O crescimento exponencial do mercado fez com que muitas pessoas investissem neste segmento, aumentando a 
                concorrência entre os Integradores Solares. Para se ter ideia, segundo os estudos da Greener, em janeiro 
                de 2019 haviam aproximadamente 6.000 empresas Integradoras, já no 1° semestre de 2020 este número mais 
                que duplicou, atingindo 14.500 empresas. Ou seja, há um mercado enorme a ser explorado e muitas 
                oportunidades, porém, seu negócio precisa estar um passo à frente da concorrência sempre!
            </p>
            
            <p>
                Mas para se adaptar às demandas e agarrar as oportunidades é preciso resiliência e mudanças. <b>Mas como
                mudar? Quando mudar?</b>
            </p>

            <p>
                Muitos gestores tem a percepção de que o crescimento gera a demanda por mudança, por adaptações, por desenvolvimento
                dentro da empresa, mudando seu negócio só depois que a empresa crescer. É aí que mora o perigo! É
                justamente a inovação e a mudança que ocasionam o crescimento. É a compreensão clara dos fluxos, processos
                e gargalos da empresa que delimitam os caminhos que o Integrador deverá seguir para prosperar.
            </p>

            <p>
                O crescimento não está diretamente ligado a vender mais, pois quando se vende mais, sem direcionamento e
                sem planejamento, o Integrador fica amarrado às atividades operacionais, precisando de mais recursos humanos
                para conseguir entregar as vendas feitas. Aumentando assim seu custo fixo e operacional e neste momento não
                há mais tempo hábil para identificar os problemas que já estão sufocando a rotina de trabalho. Esse é
                o momento limite para o Integrador mudar, ou corre o risco de ir à falência, ou mesmo ficar frustrado com
                o negócio e desistir dele.
            </p>

            <p>
                Pensando em todas as possibilidades, conhecendo a rotina do Integrador, a necessidade de mudança e cientes das
                oportunidades que o segmento oferece aos Integradores, a <b>GDPlace</b> oferece um Sistema que permite otimizar
                o tempo do Integrador em diversas frentes e oferece também a oportunidade de analisar o desempenho da empresa 
                com indicadores importantes dentro do Sistema, como por exemplo, <b>Ticket Médio, Taxa de Conversão em vendas,
                Tempo do ciclo de venda</b>, entre outras informações importantes.
            </p>

            <p>
                O <b>Sistema GDPlace</b> permite também que o Integrador aperfeiçoe a qualidade do atendimento ao seu cliente,
                oferecendo uma<b> proposta personalizada </b>com seus dados, seu logo e com informações relevantes. Além de 
                oferecer no sistema, <b>diversos equipamentos de qualidade entre diversos distribuidores.</b>
            </p>

            <p>
                O Integrador que procura por mudanças, desenvolvimento e crescimento do seu negócio, deve estar na GDPlace.
                Oferecemos um ambiente amigável dentro do Sistema e um <b>Suporte Técnico 100% gratuito</b> e especializado
                para auxiliar o Integrador no que for preciso.
            </p>

            <p>
                <b>Não fique de fora e faça parte desse time com mais de 1500 Integradores que já estão colhendo os resultados
                positivos de mudar para melhor com o Sistema GDPlace.</b>
            </p>

            <div className="d-flex justify-center my-12">
                <b>
                    <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                        <img alt="" src="https://www.gdplace.com.br/admin/upload/conteudo%20imagem.png" width='800' />
                    </a>
                </b>
            </div>
        </>
    )
}

const post9Data = () => {
    return (
        <>
                <p>
                    O Sistema Comparador de Preços GDPLACE, que atua no ramo de Energia Solar Fotovoltaica, é uma solução
                    totalmente programada para atender integradores em larga escala. Ele faz comparativos de preços com
                    vários distribuidores de kits de energia solar, é uma ferramenta inovadora, um facilitador do
                    relacionamento e comercialização de geradores de energia solar para integradores e distribuidores
                    deste segmento, o que torna ela a maior aliada na rotina de trabalho, <b>reduzindo custos</b> e potencializando resultados.
                </p>

                <p>
                    A expectativa para os próximos 3&nbsp;anos, é movimentar dentro do Sistema aproximadamente 
                    R$ 1,5 bilhões em vendas de equipamentos fotovoltaicos. Uma marca que é acessível para a GDPlace, 
                    visto que com apenas 120 dias de atividade, foi movimentado mais de um bilhão em orçamentos, entre 2.400
                    Integradores cadastrados.
                </p>

                <p>
                    O sistema foi criado para acelerar os negócios do Integrador e <b>torna-lo um verdadeiro case de sucesso
                    no mercado de energia solar, </b>independentemente do tamanho do seu negócio. Nosso compromisso é com o crescimento 
                    do Integrador, organizando a sua rotina de trabalho, possibilitando a compra dos melhores equipamentos com os melhores 
                    preços do mercado em um só lugar.
                </p>

                <p>
                    Para isso, a GDPlace não para de inovar e está constantemente lançando novas ferramentas para facilitar a
                    rotina do integrador. Entre elas destaca-se as várias opções de montagem de kits, tanto o kit
                    personalizado, como também o kit fechado, desta forma a GDPlace atende todos os públicos e perfis de
                    integradores. &nbsp;
                </p>

                <p>
                    Além dos kits personalizados e fechados, a GDPlace lança o <b>CotaZap</b>, uma funcionalidade
                    inovadora. O <b>Integrador poderá gerar orçamentos via WhatsApp</b>, de forma ultra rápida
                    e fácil. Com apenas 5 informações, o Integrador terá em mãos o melhor preço de kit solar do mercado,
                    de forma 100% automatizada e instantânea em um ambiente premium exclusivo para cadastrados. E além
                    de gerar orçamentos, é possível fazer consultas dos orçamentos e pedidos realizados
                </p>

                <p>
                    Além destas novidades, muitas outras funcionalidades estão à caminho, como a parceria com instituições
                    financeiras e novos parceiros para que as negociações ocorram 100% dentro do sistema, até mesmo o
                    financiamento da mão de obra dos projetos fotovoltaicos.
                </p>

                <p>
                    O sistema GDPlace veio para ficar e conquistar o mercado de energia solar. Hoje ele já reúne vários
                    distribuidores oferecendo produtos e equipamentos de alta qualidade com os preços mais acessíveis do
                    mercado.
                </p>

                <div className="d-flex justify-center">
                    <Blockquote
                        className="xs:my-0 my-6 w-[80%]"
                        theme="blockquote-style-02"
                        title="A GDPlace foi desenvolvida para ser a ferramenta indispensável para o integrador que realmente
                        deseja aumentar suas vendas e reduzir custos, alavancar o negócio da empresa integradora."
                        author="Orlando José, CEO da GDPlace"
                    />
                </div>
        </>
    )
}

const post10Data = () => {
    return (
        <>
            <p>
                O <b>Sistema Comparador de Preços GDPlace</b> agora oferece a possibilidade de construir Kits
                Fotovoltaicos com <b>Micros Inversores</b>. A novidade chega para agregar ao sistema, e trazer ainda
                mais opções ao Integrador, para que o mesmo possa atender diversos tipos de público.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Mas qual é o principal diferencial do Micro Inversor?
                </h7>
            </div>
            
            <p>
                O principal diferencial do Micro Inversor, é o fato não existir ligação em série de painéis
                solares.
            </p>

            <p>
                Desta forma, possibilita que cada painel trabalhe com toda a sua potência, independente dos outros
                presentes no mesmo arranjo. Permite também o monitoramento individualizado, trazendo controle
                e agilidade para investigar qualquer situação sem necessidade de intervenção da equipe técnica
                no local. Esses enormes benefícios ao seu sistema, se traduzem em melhores retornos financeiros 
                ao seu investimento.
            </p>

            <p>
                <b>Cadastre-se</b> e conheça o <b>Sistema Comparador de Preços GDPlace</b>, o 
                uso é <b>100% Gratuito.</b>
            </p>

            <p>
                Não perca tempo Integrador!!
            </p>

            <div className="d-flex justify-center my-12">
                <a aria-label="gdplace" href="https://www.gdplace.com.br/cadastro/" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/Arte_cta%20blog.png" width="800" />
                </a>
            </div>
        </>
    )
}

const post11Data = () => {
    return (
        <>
            <p>
                A indústria de energia solar está, literalmente, radiante. Mesmo em meio a pandemia do corona
                vírus, ela conseguiu bater recordes em 2020. A instalação de painéis solares cresceu 70% no ano
                passado, gerando 7,5 giga watts, o equivalente à metade da energia gerada pela hidrelétrica de
                Itaipu.
            </p>

            <p>
                Todo esse crescimento refletiu diretamente nos resultados da <b>GDPlace</b>, que já <b>contabiliza 
                mais de 2500 integradores cadastrados</b> no sistema e mais de um bilhão em orçamentos. Enquanto
                alguns setores da economia tiveram dificuldades em se reerguer, o setor solar colhe os frutos 
                da necessidade das pessoas e empresas em reduzir a conta de luz, bem como a conscientização mundial
                com relação ao meio-ambiente e o uso de energia renováveis.
            </p>
            
            <p>
                As metas para 2021 são desafiadoras mas a empresa está otimista, apostando na força do mercado 
                fotovoltaico e na certeza de ter um sistema único, inovador e 100% confiável disponível no mercado.
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6 w-[80%]"
                    theme="blockquote-style-02"
                    title="A GDPlace pretende se tornar em 2021, um dos maiores e melhores ambientes de compra e 
                    gestão para Integradores do setor solar, e estamos trabalhando para que o Integrador consiga
                    realizar todas as suas atividades dentro do Sistema, desde o orçamento, pedidos, financiamentos 
                    e gestão de vendas e processos. Sendo o único sistema 100% gratuito e de fácil acesso a
                    todos os integradores."
                    author="Orlando José, CEO da GDPlace"
                />
            </div>

            <p>
                Para a segunda quinzena de Janeiro, iniciando o ano com o pé direito, a <b>GDPlace anuncia
                diversas novidades no sistema comparador de preços</b>.
            </p>

            <p>
                A principal delas é o lançamento de <b>Kits Solares Promocionais</b>, que permitirão ao 
                integrador aproveitar ofertas exclusivas oferecidas diretamente pelos distribuidores parceiros do
                Sistema GDPlace. Esse será mais um grande motivo para nenhum integrador deixar de
                utilizar o sistema, afinal, será mais uma possibilidade de fechar excelentes negócios com
                apenas alguns cliques!
            </p>

            <p>Outra novidade que a GDPlace traz para os Integradores é a <b>possibilidade de construir Kits
                Fotovoltaicos com Micros Inversores</b> e <b>Kits solares com Código Finame</b>. Esses 
                diferenciais chegam para somar e trazer ainda mais opções para que o Integrador possa atender
                diversos tipos de público.
            </p>

            <div className="d-flex justify-center my-12">
                <img alt="" src="https://www.gdplace.com.br/admin/upload/GD%20Place%2014-01-21%20Pauta-13.jpg" width="450" />
            </div>
            
            <p>
                <b>Destacamos ainda que, no final de 2020, passamos a oferecer a funcionalidade CotaZap</b>, 
                que permitiu ao integrador gerar orçamentos via WhatsApp, de forma ultra rápida e fácil.
                Com apenas 5 informações, o Integrador terá em mãos o melhor preço de kit solar do mercado,
                de forma 100% automatizada e instantânea em um ambiente <em>Premium</em>, exclusivo para 
                cadastrados. A ferramenta ainda permite consultar os orçamentos e pedidos realizados a qualquer 
                momento.
            </p>

            <p>
                Além dessas novidades, o <b>Sistema GDPlace já conta com 8 grandes distribuidores &nbsp;</b> 
                e está preparando para <b>apresentar mais 4 distribuidores parceiros</b> em breve, 
                aumentando ainda mais as opções de equipamentos e de preços.
            </p>

            <p>
                A empresa reforça que o objetivo da criação do sistema é promover o crescimento sustentável de
                todos os envolvidos, do distribuidor ao integrador, facilitando processos, otimizando tempo e
                consequentemente aumentando rendimentos.
            </p>
        </>
    )
}

const post12Data = () => {
    return (
        <>
            <p>
                A <b>GDPlace </b>vem mostrando ao mercado fotovoltaico, que o <b>Integrador precisa “virar a
                chave”</b>, ou seja, compreender que a gestão dos processos está diretamente ligada ao
                resultado nas vendas, refletindo diretamente na melhora do seu resultado financeiro.
            </p>

            <p>
                Assunto que é cada vez mais discutido no mercado, recentemente, <b>Orlando José, CEO da
                GDPlace</b>, foi convidado por alguns especialistas do segmento para debater assuntos
                de <b>como otimizar a performance em vendas</b>, utilizando as ferramentas disponíveis no mercado,
                estratégias de vendas, e destacar a importância de ter processos internos bem definidos e organizados,
                que estão diretamente ligados aos resultados da empresa, e também para <b>demonstrar como funciona na
                prática o Sistema GDPlace</b>.
            </p>
            
            <p>
                <b>Por este motivo a GDPlace está abrindo um espaço específico para parceiros de treinamentos 
                dentro do Sistem</b>a, para oferecer ao Integrador cursos técnicos, cursos de vendas, 
                de gestão, entre outros, para que o <b>integrador possa fortalecer ainda mais o seu negócio</b>
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6"
                    theme="blockquote-style-02"
                    title="Queremos ajudar o setor solar das mais variadasformas, as parcerias de treinamento firmadas, só reforçam o 
                    crescimento da GDPlace. Nosso modelo de Sistema, foi desenvolvido para atender todas as demandas
                    dos Integradores, com o objetivo de ser uma aceleradora de novos negócios, entregando os
                    melhores produtos com os melhores preços, otimizando a gestão de vendas e demais processos.
                    Nossa ferramenta permite ao Integrador aumentar a prospecção de novas oportunidades de negócios,
                    em consequência fechando mais projetos."
                    author="Orlando José, CEO da GDPlace"
                />
            </div>

            <p>
                Atualmente o<b> Sistema GDPlace</b> conta com mais de 2800 empresas Integradoras cadastradas,
                mais de 2,1 bilhões em orçamentos, 10 distribuidores parceiros ativos e conta com uma
                equipe de suporte técnico especializada para atender o Integrador.
            </p>
        </>
    )
}

const post13Data = () => {
    return (
        <>
            <p>
                O <b>Sistema GDPlace</b> continua conquistando diversos Integradores pelo Brasil, o número de orçamentos e pedidos tem aumentado exponencialmente, e com o crescimento,
                aumenta também a <b>responsabilidade de entregar soluções ainda melhores ao mercado.</b>
            </p>

            <p>
                Apesar das consequências da pandemia que ainda assolam a economia e o mercado no geral, de acordo
                com as estimativas da Associação Brasileira de Energia Solar Fotovoltaica (ABSOLAR) o ano de 2021
                trará um acréscimo de 5,09 GW em potência instalada de energia solarfotovoltaica no Brasil. Isto
                representa um aumento de 68% em relação ao acumulado até o fim de 2020.
            </p>
            
            <p>
                Motivados com a expectativa de crescimento do setor, os esforços da <b>GDPlace estão focados
                em atualizações constantes</b>, em trazer conteúdos relevantes para os Integradores parceiros,
                e novidades no Sistema que trarão maiores facilidades na rotina, visando <b>sempre o aumento de
                resultados do Integrador</b>, oferecendo as <b>melhores oportunidades de negócios.</b>
            </p>

            <p>
                Uma dessas novidades, é o <b>Chat GDPlace</b>, um <b>canal onde o Integrador terá acesso
                direto com o Distribuidor parceiro, ou seja, a oportunidade de garantir o melhor negócio 
                está diante de um Sistema 100% gratuito, que oferece diversos outros benefícios para que
                o Integrador consiga organizar sua rotina e alavancar o seu negócio.</b>
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6"
                    theme="blockquote-style-02"
                    title="O Chat GDPlace é um pedido de diversos Integradores parceiros da GDPlace, e obviamente
                    nós procuramos atender... Entendemos que a confiança e a transparência são indispensáveis em
                    qualquer negócio, o Chat GDPlace irá aproximar e gerar ainda mais e novos negócios. Queremos que
                    o Integrador cresça, e para que isso aconteça o Sistema GDPlace é a ferramenta indispensável para
                    alavancar seus negócios de forma organizada, encontrando os melhores preços e equipamentos que
                    o mercado oferece."
                    author="Orlando José, CEO da GDPlace"
                />
            </div>

            <p>
                Cadastre-se, é 100% gratuito, realize seus orçamentos, pedidos e acompanhe todo processo de
                suas vendas através do Sistema GDPlace e tenha a melhor experiência.
            </p>
        </>
    )
}

const post14ListData = [
    {
        icon: "feather-arrow-right-circle",
        content: "Aumenta agilidade em todos os processos",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Informações e preços integrados e automatizadas",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Redução de Custos com mão de obra",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Eliminação do retrabalho com atualização de planilhas e com propostas comerciais com preços desatualizados",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Otimização do tempo de trabalho de toda a equipe",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Proposta comercial automatizada",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Atualização de Proposta, sobre uma proposta já existente",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Envio de propostas via e-mail e whatsapp",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Uso do chat online, para envio de mensagens via sistema GDPlace",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Suporte técnico especializado",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "E possibilita um maior foco em atividades essenciais para o negócio",
        subcontent: []
    },
  ]

const post14Data = () => {
    return (
        <>
            <h6 className="font-serif text-darkgray font-medium mb-3">
                Importante entender...
            </h6>

            <p>
                Antes de descrevermos e realizarmos o comparativo, é importante entender qual foram as
                motivações para o desenvolvimento do Sistema GDPlace.
            </p>

            <p>
                Antes do projeto <b>GDPlace</b> ser desenvolvido, <b>éramos uma empresa integradora</b>.
                Portanto, sentimos na pele as diversas dificuldades comuns das empresas do ramo. Essa
                experiência de 8 anos, nos proporcionou conhecimento e autonomia para criar um novo modelo
                de negócio, capaz de suprir a falta de uma ferramenta aceleradora de novos negócios.
            </p>

            <p>
                Nossa grande dificuldade sempre foi a busca por kits solares adequados, assim como o
                dimensionamento de sistemas, acompanhamento do desempenho da equipe, geração das propostas,
                entre outros problemas recorrentes. Mas a nossa maior dificuldade, que realmente nos chamava
                a atenção, era o tempo perdido cotando o mesmo kit com vários distribuidores na busca da
                melhor condição de compra. Comparar o melhor custo benefício, parecia uma missão impossível.
            </p>

            <p> 
                A <b>dificuldade ficava ainda maior, pois o controle era feito através de planilhas em
                Excel</b> que <b>despediam muito tempo para atualizações </b>e uma pessoa exclusiva no
                setor operacional para esta tarefa. Muitas fórmulas, muitas informações e obviamente, era
                comum encontrar erros e inconsistências nas planilhas.
            </p>

            <p>
                Ou seja, despedíamos muito tempo, tínhamos retrabalho, custos elevados e processos lentos.
                Todos esses problemas, cortamos com a criação de uma ferramenta ágil, integrada com os
                distribuidores e buscando sempre as melhores condições de compras para o integrador.
                Sempre acreditamos que ter um sistema adequado e processos mais eficazes, nos levaria a estar 
                à frente de nossos concorrentes
            </p>

            <h6 className="font-serif text-darkgray font-medium mb-3">
                Então, o que é melhor para o integrador?
            </h6>

            <p>
                Primeiramente, as planilhas em Excel não são integradas à nenhum distribuidor. Os preços
                não são atualizados automaticamente, é preciso importar as informações de preço para dentro
                das planilhas, e como os preços no mercado fotovoltaico são atualizados com muita frequência,
                quase que diariamente, se torna quase impossível realizar atualizações diárias. Dessa forma,
                muitas vezes o Integrador entrega propostas com valores desatualizados aos clientes.
            </p>

            <p>
                Ao entregar propostas desatualizadas, o integrador corre grandes riscos de prejuízos, sem
                contar o seu retrabalho. Na maioria das vezes, o retrabalho acaba se tornando um padrão de
                processos dentro da empresa, um verdadeiro vilão de desempenho, e no final do mês, o
                Integrador não consegue mensurar o quão oneroso e nocivo esse retrabalho foi para a empresa.
            </p>

            <p>
                <b>Ao usar GDPlace por 30 dias, o integrador acelera todos os seus processos de vendas e
                    pode ver e compreender seus problemas, que na maioria das vezes se torna imperceptível,
                    pois “sempre foi feito desta forma”. É necessário um olhar mais atento e coragem para
                    encarar o desafio da mudança, na certeza de colher melhores resultados.
                </b>
            </p>

            <p>
                No <b>Sistema GDPlace, o Integrador conta com mais de 10 distribuidores parceiros ativos</b>
                para realizar orçamentos, com as <b>tabelas de preços atualizadas em tempo real</b>.
                Ou seja, não corre risco de entregar propostas comerciais com preços desatualizados aos 
                clientes. <br /> 
                Ao otimizar o tempo de todas a pessoas de sua equipe, igualmente se reduz o custo operacional,
                com o aumento dos lucros da empresa integradora.
            </p>

            <h6 className="font-serif text-darkgray font-medium mb-3">
                    Resumidamente, com o Sistema GDPlace, os benefícios são:
            </h6>

            <Lists 
                theme="list-style-07"
                data={post14ListData} 
                className="text-darkgray font-serif px-6"
                animation={fadeIn}
            />

            <p>
                O <b>Sistema GDPlace, além de todos os benefícios e facilidades que oferece</b>,
                comparado com planilhas em Excel, <b>é 100% gratuito</b>. <b>Tornando-se a melhor
                opção para o Integrador.</b> A GDPlace, chegou para otimizar sua rotina de trabalho,
                reduzir custos, melhorar todos os seus processos, sendo o único sistema 100% gratuito
                e específico para o segmento de energia solar.
            </p>

            <p>
                <b>É hora de você crescer, vem com a GDPLACE!</b>
            </p>

            <p>
                <b>BAIXE O MATERIAL DO COMPARATIVO GRATUITO</b>
            </p>

            <p>
                <b>
                    <a aria-label="comparativo" href="https://www.gdplace.com.br/admin/upload/Sistema%20GDPlace%20x%20%20Excel%20-%20Comparativo.pdf" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                        <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width='300' />
                        
                    </a>
                </b>
            </p>
        </>
    )
}

const post15ListData = [
    {
        icon: "feather-arrow-right-circle",
        content: "Identifique seu público alvo",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Teste as redes sociais com mais alcance",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Configure as estratégias e promoções em cada uma das ferramentas de tráfego pago",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Mensure sua captação de Leads em cada uma delas",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Invista na rede social que mais te trás resultados, mas não abandone as demais",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Frequência é essencial",
        subcontent: []
    },
]

const post15Data = () => {
    return (
        <>
            <p>
                Montar o seu próprio negócio ou empresa, atualmente, é o sonho da maioria dos brasileiros. Mas qual são as
                dificuldades? Os maiores medos? As objeções? Qual é a sua experiência no mercado que pretende ingressar?
                Bem, essas são perguntas que todo empreendedor realiza no início do seu negócio.
            </p>
            
            <p>
                Empreender no segmento de energia solar não é diferente, não basta apenas ter conhecimento técnico,
                como também é essencial ter noções mínimas de gestão, e uma das maiores dúvidas de quem inicia no
                segmento de energia solar, é como captar Leads, como captar contatos, automatizar processos e rotinas
                para prospectar clientes.
            </p>

            <p>
                Inicialmente é preciso compreender que há diversos canais
                de prospecção, e podemos trabalhar com eles das mais variadas formas, que partem do próprio networking
                do empreendedor, redes sociais, google, feiras de negócio do segmento, bancos de dados de
                associações.
            </p>

            <p>
                Contudo,
                antes de qualquer atividade de prospecção, a
                empresa precisa definir o seu público alvo, e por quê? Fica mais fácil definir estratégias de prospecção
                à partir do momento que temos bem definido qual é o público alvo, onde encontra-los, quais são suas
                demandas, e possíveis e principais objeções.
            </p>

            <p>
                Bem, depois de definido o público alvo, podemos definir quais são os principais canais de atração que a
                empresa poderá usar para captar os Leads. E por que, principais? Acreditamos que TODA forma de
                prospecção é válida, obviamente, a empresa irá investir no canal que mais trará resultados.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Tráfego Pago:
                </h7>
            </div>

            <p>
                O mistério mora no Tráfego Pago, como investir, o quanto investir, que canal investir? Atualmente, temos
                diversos canais como por exemplo Google, Facebook, Instagram, LinkedIn e até mesmo grupos de WhatsApp.
            </p>

            <p>
                Se você não pode contratar alguém para cuidar do Marketing da empresa ou cuidar das redes sociais, você
                precisa ter o mínimo de informações para investir e configurar suas estratégias para garantir uma
                captação razoável de Leads.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium mb-3">
                    A Fórmula Mágica não existe:
                </h7>
            </div>

            <p>
                É isso mesmo... A fórmula mágica ou receita certa não existe, o que pode dar certo para alguns Integradores,
                para outros não há resultados expressivos, influenciada por diversas variáveis, dia, hora, público alvo,
                frequência. Mas o que podemos garantir é preciso realizar testes em todos os canais, identificar o que mais trás 
                Leads qualificados e resultam em vendas, e a frequência.
            </p>

            <p>
                Você será penalizado pelas redes sociais, se não garantir frequência em seus materiais
                publicados.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium mb-3">
                    Resumindo:
                </h7>
            </div>

            <Lists 
                theme="list-style-07"
                data={post15ListData} 
                className="text-darkgray font-serif px-6"
                animation={fadeIn}
            />

            <hr className="my-[24px]"></hr>

            <div className="d-flex align-items-center flex-column">
                <p>
                    <b>Cadastre-se</b> e conheça o <b>Sistema Comparador de Preços GDPlace</b>, o uso 
                    é <b> 100% Gratuito.</b>
                </p>

                <p>Não perca tempo Integrador, e alavanque o seu negócio de energia solar.</p>
            </div>

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Ainda não é cadastro no Sistema GDPlace? &nbsp;</b> Cadastre-se agora mesmo e comece agora
                mesmo a mudar a realidade do seu negócio de energia solar.
            </p>

            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post16ListData = [
    {
        icon: "feather-arrow-right-circle",
        content: "Geração de Propostas com Preços de equipamentos desatualizados",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Perda de tempo com atualização de Preços em Planilhas em Excel",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Perda de tempo com correção de erros e inconsistências em Cálculos em Planilhas",
        subcontent: []
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Falta de Acompanhamento de Orçamentos",
        subcontent: [
            {
                icon: "feather-arrow-right-circle",
                content: "Falta de controle",
            },
            {
                icon: "feather-arrow-right-circle",
                content: "Necessário refazer por perda do arquivo",
            }
        ]
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Muitas informações relevantes se perdem pelo caminho",
        subcontent: [
            {
                icon: "feather-arrow-right-circle",
                content: "Descontos",
            },
            {
                icon: "feather-arrow-right-circle",
                content: "Atualização de Propostas",
            },
            {
                icon: "feather-arrow-right-circle",
                content: "Conversa com cliente",
            }
        ]
    },
]

const post16Data = () => {
    return (
        <>
            <p>
                O <b>retrabalho é um dos maiores vilões de qualquer empresa</b>, principalmente <b>empresas
                Integradoras que insistem em utilizar planilhas em excel para fazer a gestão do seu
                negócio.</b>
            </p>

            <p>
                Por <b>MUITAS VEZES imperceptível</b>, o retrabalho pode resultar em uma saúde financeira
                instável ou até mesmo a falência do seu negócio.
            </p>
    
            <p>
                A falta de resiliência causa a <em>“cegueira seletiva”,</em> ou seja, o problema é visível, pode 
                ser resolvido facilmente em pouco tempo, contudo, alguma etapa do processo de mudança e adaptação 
                ouvimos a ‘célebre’ frase: <em>“SEMPRE FOI FEITO ASSIM”</em>. <b>Uma frase que se não eliminada
                o mais rápido possível, pode causar danos irreversíveis à empresa.</b>
            </p>

            <p>
                A compreensão dos danos que o retrabalho pode causar é fácil, mas como encontrar os gargalos
                da sua empresa? <b>Como identificar as atividades que causam retrabalho? Como identificar os
                motivos do retrabalho?</b>
            </p>

            <p>
                <b>Elencamos alguns pontos de gargalos e atividades que causam retrabalho dentro de Empresas
                Integradoras</b>, se você se identificar com algum desses itens, seja rápido na resolução dos
                mesmos:
            </p>

            <Lists 
                theme="list-style-07"
                data={post16ListData} 
                className="text-darkgray font-serif px-6"
                animation={fadeIn}
            />
           
            <h6 className="font-serif text-darkgray font-medium mb-3">
                Como ELIMINAR essas atividades que geram RETRABALHO?
            </h6>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Padronização de processos com o Sistema GDPlace
                </h7>
            </div>

            <p>O Sistema GDPlace, além de comparar e entregar os melhores preços e equipamentos do mercado,
                permite o Integrador a padronizar os processos.
            </p>

            <p>
                Quando todos os processos ficam restritos à um Sistema ou a um método, a gestão e operação andam
                juntas e organizadas. O Sistema GDPlace conta com mais de 12 Distribuidores, com tabela de preços
                atualizada diariamente automaticamente, com isso o Integrador consegue realizar orçamentos,
                gerir os orçamentos, atualizar propostas, acompanhar as propostas em aberto, gerar os pedidos e
                acompanha-los até a entrega.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Redução de Custos Operacionais
                </h7>
            </div>

            <p>
                A redução de custos está diretamente ligada à padronização e gestão de processos. Se com o
                Sistema GDPlace, o Integrador consegue otimizar o tempo, com a geração de orçamentos, 
                acompanhamento dos mesmos, sobra mais tempo, para fechar mais negócios, focar em vendas e 
                atendimento de clientes.
            </p>

            <p>
                A otimização de tempo e a eliminação do retrabalho, possibilita um maior aproveitamento da equipe
                comercial, em atividades que realmente trazem resultados à empresa. Os custos que o retrabalho gera
                são imperceptíveis no dia a dia, contudo são nocivos e onerosos no final do mês, e todos as
                atividades elencadas anteriormente podem ser facilmente combatidas após adotar o Sistema GDPlace
                nas atividades da empresa Integradora.
            </p>

            <p>
                <b>
                    O Sistema GDPlace foi desenvolvido para otimizar sua rotina de trabalho, reduzir custos, 
                    melhorar todos os seus processos, sendo o único sistema 100% gratuito e específico para
                    o segmento de energia solar.
                </b>
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium mb-3">
                    É hora de você crescer, vem com a GDPLACE!
                </h7>
            </div>

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post17Data = () => {
    return (
        <>
            <p>
                <b>Integrador, você é novo no setor solar? Não sabe por onde começar sua caminhada?</b>
            </p>

            <p>
                <b>Ou já está inserido e não tem um planejamento concreto? A GDPlace te ajuda...</b>
            </p>

            <p>
                O segmento de energia solar, é um dos mercados que mais cresce atualmente no cenário econômico
                nacional. É natural as pessoas verem uma oportunidade de investimento para otimizar seus
                ganhos, ou até mesmo se aventurarem completamente nesse universo, que também é muito novo e
                tem muito a crescer no Brasil.
            </p>

            <p>
                Contudo, apesar da oportunidade e de ser um segmento que está dando seus primeiros passos 
                no cenário nacional, já tem players importantes e que estão ditando a velocidade de
                crescimento do mercado, sejam eles distribuidores ou até mesmo empresas integradoras bem
                estruturadas.
            </p>

            <p>
                <b>E como se inserir, como organizar sua empresa integradora para que cresça e ganhe espaço
                no mercado de energia solar?</b>
            </p>

            <p>
                <b>Planejamento</b> é a <b>palavra-chave</b> de qualquer negócio, e para energia solar não
                é diferente. A primeira pergunta que fazemos é, sua empresa tem missão, visão e valores
                definidos? Você sabe a diferença entre eles?
            </p>

            <p>
                <em>Missão:</em> É o propósito de a&nbsp;empresa&nbsp;existir.&nbsp;
            </p>

            <p>
                <em>Visão:</em> É a situação em que a&nbsp;empresa&nbsp;deseja chegar (em período definido de
                tempo).&nbsp;
            </p>

            <p>
                <em>Valores:</em> são os ideais de atitude, comportamento e resultados que devem estar presentes
                nos colaboradores e nas relações da&nbsp;empresa&nbsp;com seus clientes, fornecedores e 
                parceiros.
            </p>

            <p>
                Então, a partir desses 3 pilares, você pode direcionar a sua empresa, e consegue definir estratégias
                que condizem com sua visão. Toda empresa deve começar sabendo onde quer chegar.
            </p>

            <p>
                Após definido os pilares, o planejamento de uma forma geral fica mais fácil, consegue-se definir com
                clareza o público alvo, e que tipo de estratégias deverão ser tomadas para abordá-los, fica mais fácil
                também engajar sua equipe, pois se o colaborador sentir que a empresa é sólida, a segurança aumenta e
                ele sabe que trabalho desempenhar para gerar o resultado esperado.
            </p>

            <p>
                Para <b>auxiliar ainda mais os Integradores parceiros, a GDPlace está oferecendo uma Mentoria 100% Gratuita, 
                chamada Integrador 4.0 | Alta Performance em Vendas.</b> Que aborda assuntos como obter um bom
                planejamento, até dicas de estratégias de vendas valiosas para alavancar o seu negócio de energia solar.
            
                <b>Quer fazer parte deste time CAMPEÃO EM VENDAS?</b>
            </p>

            <p>
                <b>Basta acessar o link e realizar o cadastro:</b>
            </p>

            <p>
                <a href="https://marketing.gdplace.com.br/mentoriaintegrador4-0">
                    https://marketing.gdplace.com.br/mentoriaintegrador4-0
                </a>
            </p>

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post18Data = () => {
    return (
        <>
            <p>
                Lideranças, profissionais e simpatizantes do setor fotovoltaico&nbsp;reuniram-se na Esplanada
                dos Ministérios na manhã de terça-feira, dia 08 de junho, para pedir e pressionar o Parlamento
                a votar e aprovar o Projeto de Lei 5.829/2019.
            </p>

            <p>
                O movimento defende que a medida trará mais segurança jurídica e regulatória para o crescimento 
                sustentável do setor elétrico no Brasil. A votação do PL estava previsto para o início de 2020, mas
                foi adiado devido à pandemia do coronavírus. A pauta foi retomada em dezembro, quando a maioria dos
                deputados votaram pela urgência da tramitação do texto, mas a proposta ainda não foi analisada pela
                Câmara.
            </p>

            <p>
                O setor defende que a medida é fundamental para evitar o cenário de altas da energia elétrica e riscos
                de apagões devido à escassez hídrica. Dados da ABSOLAR (Associação Brasileira de Energia Solar
                Fotovoltaica) estimam que, se aprovado, o PL pode ajudar gerar cerca de 1 milhão de empregos até
                2050 e economizar R$ 175 bilhões na conta de energia de todos os consumidores.
            </p>

            <Blockquote
                className="xs:my-0 my-6"
                theme="blockquote-style-02"
                title="Somos a favor da PL, e gostaríamos que essa manifestação atingisse todos os Deputados
                para que, de fato, eles entendam a importância do setor fotovoltaico, que apesar dos danos
                causados pela pandemia, cresce dia após dia, e gera renda e emprego a muitos brasileiros.
                A PL também traz também segurança jurídica à todos os envolvidos no setor fotovoltaico, seja
                cliente ou fornecedor. A GDPlace apoia 100% a PL 5829 e estive presente na manifestação."
                author="Orlando José, CEO da GDPlace"
            />

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post19Data = () => {
    return (
        <>
            <p>
                A grande maioria dos Integradores, ainda não associam o Marketing como ferramenta de alavancagem de
                vendas e por isso cometem o maior erro que é, não ter o setor de Marketing, não ter pessoas focadas
                em atividades voltadas para o Marketing.
            </p>

            <p>
                Portanto, se o Integrador ainda não associou Marketing com Vendas, e dentro de sua empresa ele
                não possui um Marketing estruturado, provavelmente, o básico não está sendo feito. Mas o que é
                o básico?
            </p>

            <p>
                O “básico” para o Marketing na energia solar é estar presente no digital, isto é, ter um site bem
                estruturado, e que se comunique com o seu público-alvo, e também estar presente também nas redes
                sociais. Acreditamos que, não há mais espaço no mercado, para quem não se atualizou, não há mais
                espaço no mercado para quem não faz o básico e não está presente nas redes
                sociais.
            </p>

            <p>
                Redes sociais no segmento de energia solar, ela tem como maior objetivo, gerar credibilidade e
                confiança nos serviços que o Integrador presta. Mas como gerar essa credibilidade? Trabalhando em
                materiais que divulguem as obras já instaladas, depoimentos de clientes satisfeitos com serviços,
                solicitar contas de luz ao seu cliente após a instalação do sistema fotovoltaico e mostrar na 
                prática do antes e depois.
            </p>

            <p>
                E muitas vezes, esses materiais que podem vir ser utilizados nas redes sociais, é um conteúdo 
                gratuito, as obras já estão instaladas, não custa “quase nada” tirar fotos dos Sistemas Fotovoltaicos,
                informar a cidade, etapas de evolução, etc.
            </p>

            <p>
                E isso apenas é o básico, contudo, o básico bem feito pode gerar resultados incríveis à sua empresa de
                energia solar, e qualquer investimento que você possa fazer em Marketing na sua empresa, com
                certeza, o “payback” será quase que imediato.
            </p>


            <p>
                Após um amadurecimento das atividades de Marketing, outros passos poderão ser dados, como
                investir em tráfego pago e captação de leads, geração de conteúdo para blog, notícias, entre
                outras atividades que possam gerar engajamento e credibilidade.
            </p>

            <p>
                <b>
                    Portanto integrador, associe Marketing com Vendas, ou melhor, associe o Marketing como
                    potencializador de suas Vendas em Energia Solar.
                </b>
            </p>

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post20Data = () => {
    return (
        <>
            <p>
                De acordo com as estimativas da Associação Brasileira de Energia Solar Fotovoltaica (ABSOLAR)
                o ano de 2021 trará um acréscimo de 5,09 GW em potência instalada de energia solar fotovoltaica 
                no Brasil. Isto representa um aumento de 68% em relação ao acumulado até o fim de 2020.
            </p>

            <p>
                Essa estimativa de crescimento se confirma quando comparamos com o crescimento e evolução em vendas
                no <b>Sistema GDPlace</b>, o <b>aumento</b> <b>representou mais de 400% em relação ao semestre 
                anterior.</b>
            </p>

            <p>
                Trata-se de um crescimento expressivo, e que demonstra que o <b>Integrador confia na GDPlace</b>, confia 
                no modelo de negócio oferecido e que confia também, em toda a equipe que trabalha diariamente para 
                entregar um Sistema completo e que otimiza rotina e atividades.
            </p>

            <p>
                No final de 2020, Orlando, CEO da GDPlace comentou que as metas para 2021 seriam desafiadoras, contudo,
                seria um ano em que a GDPlace traria ainda mais inovações, e opções para o Integrador que também
                querem acompanhar o crescimento da energia fotovoltaica no país.
            </p>

            <p>
                A GDPlace também tem oferecido aos Integradores parceiros, uma Mentoria 100% Gratuita, voltada para
                otimizar e aumentar a performance de Vendas, trazendo pontos e processos importantes para obter bons
                resultados em vendas, como, planejamento correto, engajamento da equipe de vendas, marketing, como
                traçar metas e indicadores importantes para o negócio, como e por que monitorar e por final, dicas
                de vendas, gatilhos mentais.
            </p>

            <p>
                Mais de 15 turmas já participaram da Mentoria, e vem colhendo bons resultados após aplicar as dicas
                e conhecimento oferecido durante todo o processo.
            </p>

            <p>
                Sobre o <b>Sistema GDPlace</b>, muitas novas funcionalidades foram apresentadas ao Integrador 
                parceiro (você pode conferir todas elas no Blog ou nas Redes Sociais da GDPlace), no entanto,
                a <b>GDPlace prepara-se para apresentar uma nova funcionalidade que trará ainda mais facilidade,
                organização e mais e melhores possibilidades de negócios ao Integrador parceiro.</b>
            </p>

            <Blockquote
                className="xs:my-0 my-6"
                theme="blockquote-style-02"
                title="A GDPlace está se preparando para entrar em uma nova fase do seu negócio. Os números nos
                mostram que acertamos aqui, e precisamos continuar inovando e trazendo soluções ainda melhores
                e que possa trazer ainda mais facilidade e entendemos que podemos levar a todos ainda mais
                negócios, com maior organização e estratégias de captação inteligentes. Preparem-se para uma
                nova GDPlace"
                author="Orlando José, CEO da GDPlace"
            />

            <p>
                Com menos de 1 ano, <b>o Sistema GDPlace</b> já conta <b>com mais de 3200 Integradores</b>
                Cadastrados, <b>mais de R$ 2,5 bilhões em orçamentos gerados através do Sistema</b> e uma
                equipe especializada para atender o Integrador em diversas frentes, como Suporte Técnico e
                Comercial.
            </p>
    
            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post21Data = () => {
    return (
        <>
            <p>
                <b>
                    <em>Quanto tempo o gás de um refrigerante dura depois de aberto?</em>
                </b>
            </p>
        
            <p>
                Você deve estar se perguntando... O que essa pergunta "tem a ver" com o meu atendimento, ou com o
                meu cliente de energia solar? <b>Tudo... Entenda</b>.
            </p>

            <p>
                Costumamos comparar um cliente de energia solar, com a duração do gás de um refrigerante depois de 
                aberto. O refrigerante depois de aberto dever ser consumido rapidamente, certo? O cliente da mesma
                forma, depois de cair na sua base ou lista de prospecção, ele deve ser ativado imediatamente para
                'não perder o gás', ou seja, para não cair no esquecimento nem de sua equipe comercial e para que 
                o cliente também não “esqueça” sobre o interesse em adquirir um sistema fotovoltaico ou 
                “poupar 95% na sua fatura de energia”.
            </p>
        
            <p>
                Como ativá-lo imediatamente? Como abordá-lo? O que apresentar em um primeiro contato?
            </p>

            <p>
                Geralmente, o primeiro contato deverá ser feito nos
                primeiros 30 minutos após o primeiro contato, ou seja, após este Lead cair na sua base de contatos.
                O vendedor deverá compreender a demanda, e em que nível ou estágio de interesse e conhecimento sobre
                a energia solar e seus benefícios tem o cliente.
            </p>

            <p>
                <b>Não deixe seu cliente perder o gás!</b>
            </p>

            <p>
                <b>Que estratégias tomar para que seu cliente "não perca o gás?"</b>
            </p>

            <p>
                Apresente o mais rápido possível uma proposta comercial objetiva, simples e resumida. A apresentação
                rápida de uma proposta comercial, objetiva e bem estruturada, mostra ao seu cliente o nível do seu
                serviço, e o quanto sua empresa está organizada para atendê-lo.
            </p>

            <p>
                <b>A primeira impressão é a que fica!</b>
            </p>

            <p>
                Nesse caso, sim! A primeira impressão é a que fica. Se você atendeu esse Lead, com rapidez, "antes
                que ele perdesse o gás", apresentou uma proposta comercial organizada e objetiva em pouco tempo, e
                já mostrou que sua empresa é organizada, a chance de fechar um negócio aumenta consideravelmente.
            </p>
            
            <p>
                As perguntas que ficam são... Como gerar orçamentos rapidamente, como apresentar ao meu cliente um Kit
                solar de qualidade com um bom preço? E como organizar uma proposta comercial rapidamente, se todo esse
                processo se feito manualmente é demorado?
            </p>

            <p>
                O Sistema GDPlace permite você gerar mais de 50 orçamentos rapidamente, entre diversas marcas de
                equipamentos e distribuidores parceiros. Atualmente conta com o maior portfólio do mercado.
            </p>

            <p>
                O Sistema GDPlace gera propostas personalizadas, organizadas e resumidas, com sua logo e informações 
                do seu cliente, rapidamente. É possível também enviar essas propostas por e-mail e também por
                WhatsApp. Ou seja, em pouco tempo, você atendeu seu cliente, gerou uma proposta comercial 
                assertiva, com equipamentos de qualidade e enviou ao seu cliente em pouco tempo, com um
                Sistema 100% gratuito.
            </p>

            <p>
                Conheça o Sistema GDPlace, cadastre-se. Cresça, otimize aumente sua velocidade de atendimento,
                organize sua rotina, encontre os melhores preços do mercado e venda muito mais sem pagar nada
                por isso, com um Sistema 100% gratuito.
            </p>
        
            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>


            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

const post22Data = () => {
    return (
        <>
            <p>
                <b>Principais Pilares de uma Empresa de Energia Solar</b>
            </p>

            <p>
                Aumentar as vendas, aumentar os resultados é o objetivo de toda e qualquer empresa. 
                Mas como de fato, chegar lá? Não há fórmula mágica, é uma série de processos, de tarefas 
                que devem se tornar cotidianas para que os resultados apareçam gradativamente.
            </p>

            <p>
                Reflita:
            </p>

            <p>
                Sua empresa tem um planejamento sólido? Sua equipe está ciente de onde sua empresa quer
                chegar?
            </p>

            <p>
                Você possui um processo de seleção de equipes que busca por profissionais
                capacitados e que tenham competências alinhadas com o segmento de energia solar e suas
                particularidades?
            </p>

            <p>
                Você capacita seus colaboradores? Consegue engaja-los a ponto de ter uma
                equipe que consegue gerenciar suas atividades? Você dá essa liberdade?
            </p>

            <p>
                Você tem indicadores de desempenho? Monitora o resultado de sua empresa e colaboradores?
                Consegue antecipar falhas, erros, problemas que podem estar afetando o seu resultado?
            </p>

            <p>
                Acompanhe abaixo alguns pontos específicos e reforçar que para aumentar os
                resultados não basta contratar mais vendedores, e sim, planejar, monitorar e
                corrigir.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Planejamento:
                </h7>
            </div>

            <p>
                Você possui um bom planejamento? Sua empresa sabe onde quer chegar? Tem Missão, visão e
                valores bem definidos?
            </p>

            <p>
                Tudo fica mais fácil para a empresa e para toda sua equipe quando todos sabem
                onde a empresa quer chegar e quais são os objetivos que ela quer alcançar em um
                determinado espaço de tempo.
            </p>

            <p>
                Planeje, defina metas, defina seu público alvo e compartilhe com sua equipe.
            </p>

            <p>
                Uma equipe engajada, com um planejamento sólido terá melhores resultados a curto e
                longo prazo.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Seleção de Equipe:
                </h7>
            </div>

            <p>
                Integrador, como funciona o seu processo de seleção de equipe?
            </p>

            <p>
                Tenha em mente que o segmento de energia solar é algo novo e requer alguns conhecimentos
                específico.
            </p>

            <p>
                Então procure por pessoas dispostas a estudar as complexidades do segmento, e profissionais
                que já estiveram em segmentos que dependem de um processo consultivo para vendas e outras
                atividades dentro do segmento. Como por exemplo, venda de imóveis, carros, seguros.
            </p>
            
            <p>
                Outra sugestão é contratar empresas de RH especializadas em processo de seleção de equipe,
                a assertividade das contratações seria maior, e reduz o índice e custos com demissões, reduzem
                as falhas de profissionais não qualificados e obviamente, evita o custo de um novo processo de
                seleção e contratação.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Capacitação:
                </h7>
            </div>

            <p>
                O segmento de energia solar contém algumas particularidades, tanto técnicas
                quanto de legislação.
            </p>

            <p>
                A empresa que quer vender com constância precisa capacitar seus colaboradores e principal seus
                vendedores, para estar preparado para qualquer situação, seja ela técnica ou de persuasão no momento
                de uma venda um pouco mais complexa e que precisa de uma destreza comercial maior.
            </p>

            <p>
                Realizar treinamentos internos e investir em treinamentos, cursos externos para seus 
                colaboradores é uma forma de valorizar, capacitar e engajar.
            </p>

            <p>
                Obviamente que não basta apenas capacitar, sem monitorar, mas sobre o
                monitoramento e análise de desempenho falaremos em outro vídeo.
            </p>
    
            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    Monitoramento:
                </h7>
            </div>

            <p>
                Essa palavra assusta alguns gestores e empreendedores. Como realizar essa
                tarefa de forma positiva? Quais indicadores devo prestar mais atenção?
            </p>

            <p>
                A primeira pergunta é... Você tem noção da estrutura da sua empresa? Tem alguma informação
                que você, em um primeiro momento, costuma olhar para ter um parâmetro de como sua empresa
                está financeiramente ou de como está desempenhando em vendas?
            </p>

            <p>
                Se não, comece pelo básico... Avalie o resultado geral da sua empresa em faturamento e lucratividade
                (Faturamento sem lucro, não resolve o problema), e o desempenho de sua equipe comercial. Indicadores
                como ticket médio, taxa de conversão de cada vendedor, são ótimos indicadores para começar a 
                amadurecer a ideia do monitoramento e análise de desempenho de equipes.
            </p>

            <p>
                Por exemplo, com a informação de Ticket médio por vendedor, você consegue analisar qual é o
                perfil de cliente que a sua empresa geralmente costuma atender, e o perfil de cliente que cada
                vendedor costuma atender.
            </p>

            <p>
                Dessa forma, você consegue distribuir os Leads de forma mais assertiva, cada perfil de cliente
                para um determinado tipo de vendedor.
            </p>

            <p>
                Taxa de conversão de vendas por vendedor, você como gestor consegue analisar o quanto seu
                vendedor consegue converter em vendas o número de oportunidades fornecidas. Uma alta taxa
                de conversão, geralmente, mostra que o vendedor é assertivo com argumentos, gatilhos, foco,
                e tem uma boa estratégia. Já uma taxa de conversão baixa, geralmente, o contrário, mas é
                preciso analisar com cautela, levar em consideração diversos outros fatores. Talvez uma taxa
                de conversão baixa, mostra que a captação de Leads também não está sendo assertiva, a qualificação
                dos Leads não está sendo assertiva o suficiente e o comercial precisa percorrer um caminho maior
                até as vendas. Falha no planejamento de público alvo, pode ser outro problema de uma baixa taxa de
                conversão.
            </p>

            <p>
                Agora, se quiser receber mais dicas, e ter uma mentoria 100% completa, exclusiva e gratuita,
                sobre planejamento, seleção de equipe, capitação de profissionais, e monitoramento de sua equipe,
                basta acessar o Sistema GDPlace e conferir a Mentoria Integrador 4.0 – Alta Performance em Vendas.
            </p>

            <p className="d-flex justify-center">
                <b>Quer se inscrever para a Mentoria - Integrador 4.0? Clique no Link abaixo:</b>
            </p>

            <div className="d-flex justify-center mb-3">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>
        </>
    )
}

const post23Data = () => {
    return (
        <>
            <p>
                <b>O mercado de energia solar no Brasil está em plena expansão, após bater três recordes consecutivos
                em energia solar instalada, chegando na incrível marca em 2020 de 3.150MW. Neste ano, o Brasil já
                passou de 10 GW instalados, segundo a ABSOLAR.</b>
            </p>

            <p>
                <b>Os especialistas desse mercado confiam que 2021 terá um desenvolvimento ainda maior e mais dinâmico,
                visto que com a reabertura de mercado e eventos, os contatos presenciais tornam-se mais frequentes e
                necessários após o recesso do COVID-19.</b>
            </p>

            <p className="mt-3">
                <h6 className="font-serif text-darkgray font-medium">
                    Sobre a InterSolar South America 2021
                </h6>
            </p>

            <p>
                Com mais de 250 expositores, e mais de 25.000 visitantes durante os 3 dias de feira (18 de Outubro – 21
                de Outubro), a InterSolar South America 2021 proporcionou aos visitantes e expositores sessões de 
                conhecimentos, formação e treinamento.
            </p>

            <p>
                A extensa programação do congresso incluiu apresentações de mais de 100 palestrantes cobrindo todo
                tema relevante relacionado a mercados e tecnologias, estrutura jurídica para geração distribuída no
                Brasil, tendências dos mercados de geração centralizada e distribuída, o papel da fonte solar na
                matriz energética brasileira, experiências de campo em andamento, promoção e fomento de instalações
                solares fotovoltaicas, inovação e modelos de negócios, aplicações emergentes tais como fotovoltaica
                flutuante e agrofotovoltaica, normas técnicas, e sinopses de mercado e tecnologia atualizadas sobre
                armazenamento de energia.
            </p>

            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    InterSolar South America 2021 para a GDPlace
                </h7>
            </div>

            <p>
                Nesta edição de 2021, a GDPlace participou apenas como visitante sendo representada pelos sócios
                Orlando José e Arnaldo Gabriel. Os sócios puderam visitar e reforçar ainda mais as parcerias
                que estão dentro do Sistema GDPlace, e buscar por novas que possam auxiliar e otimizar a
                ferramenta para que a rotina do Integrador ganhe em agilidade, autonomia e resultados ainda maiores.
            </p>

            <p>
                Puderam também, atender Integradores que utilizam o Sistema, trocar experiências, entender ainda
                mais as necessidades e demandas do mercado e do Integrador, para aperfeiçoar ainda mais o Sistema
                GDPlace, que já se tornou a principal ferramenta de gestão de muitos Integradores.
            </p>
    
            <div className="mb-3">
                <h7 className="font-serif text-darkgray font-medium">
                    O saldo é extremamente positivo para a GDPlace
                </h7>
            </div>

            <p>
                A InterSolar 2021 para a GDPlace, foi extremamente importante. Reforçamos ainda mais as nossas
                parcerias, encontrar figuras importantes do segmento depois deste hiato de eventos, causados pelo
                Covid. Mas além de reforçar, pudemos buscar por reforços, conversar com Integradores, para voltar e
                fazer o tema de casa, para atender o Integrador cada vez melhor.
            </p>

            <p>
                Muitos integradores e parceiros, sentiram a falta de um stand da GDPlace na InterSolar, e apesar
                da ausência do Stand, isso mostra que a GDPlace está "balançando" o mercado e já se tornou uma das
                principais ferramentas do Integrador, e também é uma das empresas alvo de parcerias de sucesso.
            </p>

            <div className="d-flex justify-center">
                <Blockquote
                    className="xs:my-0 my-6 w-[80%]"
                    theme="blockquote-style-02"
                    title="Para a próxima edição pretendemos estar presentes e atender todos em 'nossa casa' e apresentar as
                    novidades incríveis que estamos trazendo para o mercado. Uma verdadeira revolução no segmento de energia solar,
                    que trará muita agilidade, autonomia e resultados aos Integradores e aos parceiros da GDPlace."
                    author="Orlando José, CEO da GDPlace"
                />
            </div>

            <div className="d-flex justify-center mb-3">
                <img alt="" src="https://www.gdplace.com.br/admin/upload/photo_2021-10-21_09-49-02.jpg" width="500" />
            </div>
        </>
    )
}

const post24Data = () => {
    return (
        <>
            <p>
                Segundo a pesquisa realizada pela Panorama Mobile Time/Opinion Box, 99% dos Brasileiros tem o
                Aplicativo instalado em seu smartphone. O que não chega a ser uma novidade. Mas como usar o WhatsApp 
                como uma poderosa ferramenta de Venda para Energia Solar?
            </p>

            <p>
                O WhatsApp oferece uma ferramenta dedicada aos negócios chamada, WhatsApp Business. Confira abaixo
                algumas dicas de como usar os benefícios do Aplicativo para tornar uma ferramenta poderosa em
                vendas:
            </p>           

            <p>
                <b>- Mensagens automáticas: </b>Você pode criar e programar mensagens automáticas, como de
                apresentação de sua empresa, primeira abordagem para solicitar algumas informações como nome, imagem
                da fatura para gerar um orçamento breve.
            </p>

            <p>
                <b>- Etiquetas:</b> Com as etiquetas que o WhatsApp Business permite colocar, você pode
                adiciona-las aos contatos, separa-los por grupos por 'momento da jornada do cliente', para
                identificar e abordar de forma personalizada cada grupo de cliente que está em um determinado
                periodo da jornada de compra.
            </p>

            <p>
                <b>- Lista de Transmissão: </b> É uma ótima funcionalidade para engajar ainda mais seu cliente,
                trazer conteúdos relevantes que façam aumentar seu nível de consciência sobre energia solar,
                estreitar relacionamentos e se aproximar cada vez mais da venda. Poderá usar as etiquetas,
                para determinar qual conteúdo cada um dos grupos irá receber através da lista de transmissão.
            </p>

            <p>
                <b>-CotaZap GDPlace: </b> Uma <b>funcionalidade inovadora da GDPlace</b>. O Integrador
                <b>poderá gerar orçamentos via WhatsApp</b>, de forma ultra rápida e fácil. Com apenas
                <b>5 informações</b>, o Integrador terá em mãos o melhor preço de kit solar do mercado,
                de forma 1<b>00% automatizada e instantânea</b> em um ambiente premium <b>exclusivo para
                cadastrados</b>. E além de gerar <b>orçamentos, é possível fazer consultas dos orçamentos
                e pedidos realizados.</b>
            </p>

            <hr className="my-[24px]"></hr>

            <p className="d-flex justify-center">
                <b>Cadastre-se&nbsp;</b> e conheça o &nbsp;<b>Sistema Comparador de Preços GDPlace</b>, o uso é 
                <b>&nbsp;100% Gratuito.</b>
            </p>

            <p className="d-flex justify-center">
                Não perca tempo Integrador, e alavanque o seu negócio de energia solar.
            </p>

            <div className="d-flex justify-center">
                <a aria-label="comparativo" href="https://sistema.gdplace.com.br" target="_blank" rel="noreferrer" className="font-serif font-medium uppercase hover:text-spanishgray">
                    <img alt="" src="https://www.gdplace.com.br/admin/upload/CLIQUE%20AQUI.png" width="200" />
                </a>
            </div>
        </>
    )
}

export const BlogGdplaceData = [
    {
        id: 1,
        category: "Funcionalidades",
        tags: ["Funcionalidades", "GDPlace", "Solar", "Energia"],
        img: "/assets/img/blog/308-2thumb.png",
        slug: CreateSlug("Muito mais que um comparador, conheça as funcionalidades da GDPlace"),
        title: "Muito mais que um comparador, conheça as funcionalidades da GDPlace",
        content: "O Sistema Comparador de preços GDPlace oferece diversas funcionalidades, além de fazer o comparativo de kits solares entre diversos distribuidores do segmento. Antes do projeto GDPlace ser...",
        post: post1Data(),
        author: 3,
        comments: 23,
        date: "27 de Agosto de 2020",
    },
    {
        id: 2,
        category: "Notícias",
        tags: ["Novidades", "Solar", "Energia", "GDPlace"],
        img: "/assets/img/blog/307-2thumb.png",
        slug: CreateSlug("Transformando sua maneira de trabalhar com energia solar"),
        title: "Transformando sua maneira de trabalhar com energia solar",
        content: "O Sistema Comparador de Preços GDPLACE é uma solução totalmente programada para atender integradores em larga escala. Uma ferramenta que faz comparativo de preços integrado com vários distribuidores...",
        post: post2Data(),
        author: 3,
        comments: 23,
        date: "29 de Agosto de 2020",
    },
    {
        id: 3,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/306-2thumb.png",
        slug: CreateSlug("Novo Sistema Comparador de Preços GDPlace"),
        title: "Novo Sistema Comparador de Preços GDPlace",
        content: "“Simplifique suas vendas”, essa é a essência da GDPLACE, que chegou para conectar integradores e distribuidores de sistemas fotovoltaicos, por meio de um sistema comparador de preços integrado com...",
        post: post3Data(),
        author: 3,
        comments: 23,
        date: "31 de Agosto de 2020",
    },
    {
        id: 4,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/309-1thumb.png",
        slug: CreateSlug("Vem aí muitas novidades no Sistema Comparador de Preços GDPlace"),
        title: "Vem aí muitas novidades no Sistema Comparador de Preços GDPlace.",
        content: "“Simplifique suas vendas”, essa é a essência da GDPLACE, que chegou para conectar integradores e distribuidores de sistemas fotovoltaicos, por meio de um sistema comparador de preços integrado com...",
        post: post4Data(),
        author: 3,
        comments: 23,
        date: "31 de Agosto de 2020",
    },
    {
        id: 5,
        category: "Notícias",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/310-2thumb.png",
        slug: CreateSlug("Suporte Técnico Um dos diferencias da GDPlace"),
        title: "Suporte Técnico: Um dos diferencias da GDPlace",
        content: "A expansão de qualquer negócio representa um grande desafio na gestão e melhoria dos processos internos, exigindo que as empresas se adequem às tecnologias e busquem soluções que otimizem o tempo e...",
        post: post5Data(),
        author: 3,
        comments: 23,
        date: "14 de Setembro de 2020",
    },
    {
        id: 6,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/311-1thumb.png",
        title: "Quais são os Indicadores importantes para o negócio de energia solar?",
        slug: CreateSlug("Quais são os Indicadores importantes para o negócio de energia solar?"),
        content: "Atualmente, administrar uma empresa requer, além de muita responsabilidade e coragem, um trabalho focado em gestão. Se o empreendedor pretende alavancar seu negócio, é preciso analisar o mercado...",
        post: post6Data(),
        author: 3,
        comments: 23,
        date: "18 de Setembro de 2020",
    },
    {
        id: 7,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/313-2thumb.png",
        slug: CreateSlug("Chegou a vez do Integrador alavancar seus negócios com o Sistema GDPlace"),
        title: "Chegou a vez do Integrador alavancar seus negócios com o Sistema GDPlace",
        content: "O mercado de negócios, independente do segmento, está cada vez mais acirrado. Novas e melhores oportunidades surgem à todo momento e a pergunta que ecoa na cabeça do gestor é: como minha empresa...",
        post: post7Data(),
        author: 3,
        comments: 23,
        date: "08 de Outubro de 2020",
    },
    {
        id: 8,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/314-1thumb.png",
        slug: CreateSlug("Integrador, é hora de inovar para crescer!"),
        title: "Integrador, é hora de inovar para crescer!",
        content: " O mercado de energia solar está em constante expansão no cenário nacional. Segundo a ABSolar, o crescimento em 2019 atingiu 212% comparado ao ano anterior, alcançando a marca de 2,4GW instalados...",
        post: post8Data(),
        author: 3,
        comments: 23,
        date: "27 de Outubro de 2020",
    },
    {
        id: 9,
        category: "Notícias",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/315-2thumb.png",
        slug: CreateSlug("GDPlace pretende movimentar 1,5 bilhões de reais em equipamentos fotovoltaicos em menos de 3 anos!"),
        title: "GDPlace pretende movimentar 1,5 bilhões de reais em equipamentos fotovoltaicos em menos de 3 anos!",
        content: "O Sistema Comparador de Preços GDPLACE, que atua no ramo de Energia Solar Fotovoltaica, é uma solução totalmente programada para atender integradores em larga escala. Ele faz comparativos de preços...",
        post: post9Data(),
        author: 3,
        comments: 23,
        date: "05 de Dezembro de 2020",
    },
    {
        id: 10,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/312-2thumb.png",
        slug: CreateSlug("Chegou | Micros Inversores no Sistema Comparador de Preços GDPlace"),
        title: "Chegou | Micros Inversores no Sistema Comparador de Preços GDPlace",
        content: "O Sistema Comparador de Preços GDPlace agora oferece a possibilidade de construir Kits Fotovoltaicos com Micros Inversores. A novidade chega para agregar ao sistema, e trazer ainda mais opções ao Integrador...",
        post: post10Data(),
        author: 3,
        comments: 23,
        date: "06 de Janeiro de 2021",
    },
    {
        id: 11,
        category: "Notícias",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/316-1thumb.png",
        slug: CreateSlug("A GDPlace pretende se tornar, um dos melhores ambientes de compra e gestão para Integradores do setor solar"),
        title: "A GDPlace pretende se tornar, um dos melhores ambientes de compra e gestão para Integradores do setor solar",
        content: "A indústria de energia solar está, literalmente, radiante. Mesmo em meio a pandemia do corona vírus, ela conseguiu bater recordes em 2020. A instalação de painéis solares cresceu 70% no ano passado...",
        post: post11Data(),
        author: 3,
        comments: 23,
        date: "26 de Janeiro de 2021",
    },
    {
        id: 12,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/317-1thumb.png",
        title: "Parcerias reforçam o Sucesso da GDPlace - afirma, Orlando José - CEO da GDPlace",
        content: "A GDPlace vem mostrando ao mercado fotovoltaico, que o Integrador precisa “virar a chave”, ou seja, compreender que a gestão dos processos está diretamente ligada ao resultado nas vendas, refletindo...",
        post: post12Data(),
        author: 3,
        comments: 23,
        date: "22 de Março de 2021",
    },
    {
        id: 13,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/318-1thumb.png",
        slug: CreateSlug("Chat GDPlace Novidade no Sistema que permite o Integrador à ter as melhores oportunidades de negócio no Solar"),
        title: "Chat GDPlace - Novidade no Sistema que permite o Integrador à ter as melhores oportunidades de negócio no Solar",
        content: "O Sistema GDPlace continua conquistando diversos Integradores pelo Brasil, o número de orçamentos e pedidos tem aumentado exponencialmente, e com o crescimento, aumenta também a responsabilidade de entregar...",
        post: post13Data(),
        author: 3,
        comments: 23,
        date: "28 de Abril de 2021",
    },
    {
        id: 14,
        category: "Comparativos",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/319-1thumb.png",
        slug: CreateSlug("O que é MELHOR? Sistema GDPlace x Excel | Comparativo completo"),
        title: "O que é MELHOR? Sistema GDPlace x Excel | Comparativo completo",
        content: "Antes de descrevermos e realizarmos o comparativo, é importante entender qual foram as motivações para o desenvolvimento do Sistema GDPlace. Antes do projeto GDPlace ser desenvolvido, éramos uma empresa integradora. Portanto....",
        post: post14Data(),
        author: 3,
        comments: 23,
        date: "04 de Maio de 2021",
    },
    {
        id: 15,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/320-1thumb.png",
        slug: CreateSlug("Como Captar Leads na Energia Solar?"),
        title: "Como Captar Leads na Energia Solar?",
        content: "Montar o seu próprio negócio ou empresa, atualmente, é o sonho da maioria dos brasileiros. Mas qual são as dificuldades? Os maiores medos? As objeções? Qual é a sua experiência no mercado que pretende ingressar? Bem...",
        post: post15Data(),
        author: 3,
        comments: 23,
        date: "05 de Maio de 2021",
    },
    {
        id: 16,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/321-1thumb.png",
        slug: CreateSlug("Como o retrabalho pode afetar seu negócio de energia solar?"),
        title: "Como o retrabalho pode afetar seu negócio de energia solar?",
        content: "O retrabalho é um dos maiores vilões de qualquer empresa, principalmente empresas Integradoras que insistem em utilizar planilhas em excel para fazer a gestão do seu negócio. Por MUITAS VEZES imperceptível, o retrabalho...",
        post: post16Data(),
        author: 3,
        comments: 23,
        date: "11 de Maio de 2021",
    },
    {
        id: 17,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/322-1thumb.png",
        slug: CreateSlug("Por onde começar o PLANEJAMENTO da sua empresa de energia solar?"),
        title: "Por onde começar o PLANEJAMENTO da sua empresa de energia solar?",
        content: "O segmento de energia solar, é um dos mercados que mais cresce atualmente no cenário econômico nacional. É natural as pessoas verem uma oportunidade de investimento para otimizar seus ganhos, ou até mesmo se aventurarem completamente...",
        post: post17Data(),
        author: 3,
        comments: 23,
        date: "23 de Maio de 2021",
    },
    {
        id: 18,
        category: "Eventos",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/324-1thumb.png",
        slug: CreateSlug("Manifestantes promovem ato a favor da Energia Solar, em Brasília | GDPlace esteve presente na manifestação"),
        title: "Manifestantes promovem ato a favor da Energia Solar, em Brasília | GDPlace esteve presente na manifestação.",
        content: "Lideranças, profissionais e simpatizantes do setor fotovoltaico reuniram-se na Esplanada dos Ministérios na manhã de terça-feira, dia 08 de junho, para pedir e pressionar o Parlamento a votar e aprovar o Projeto de Lei 5.829/2019. O movimento defende...",
        post: post18Data(),
        author: 3,
        comments: 23,
        date: "10 de Junho de 2021",
    },
    {
        id: 19,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/325-1thumb.png",
        slug: CreateSlug("Marketing Essencial para o negócio de Energia Solar"),
        title: "Marketing: Essencial para o negócio de Energia Solar",
        content: "A grande maioria dos Integradores, ainda não associam o Marketing como ferramenta de alavancagem de vendas e por isso cometem o maior erro que é, não ter o setor de Marketing, não ter pessoas focadas em atividades voltadas para o Marketing. Portanto, se...",
        post: post19Data(),
        author: 3,
        comments: 23,
        date: "14 de Junho de 2021",
    },
    {
        id: 20,
        category: "Novidades",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/326-2thumb.png",
        slug: CreateSlug("Vem aí uma Nova GDPlace"),
        title: '"Vem aí uma Nova GDPlace" - Afirma Orlando José, CEO da GDPlace',
        content: "De acordo com as estimativas da Associação Brasileira de Energia Solar Fotovoltaica (ABSOLAR) o ano de 2021 trará um acréscimo de 5,09 GW em potência instalada de energia solar fotovoltaica no Brasil. Isto representa um aumento de 68% em relação ao acumulado...",
        post: post20Data(),
        author: 3,
        comments: 23,
        date: "09 de Julho de 2021",
    },
    {
        id: 21,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/327-1thumb.png",
        slug: CreateSlug("A Importância de um Atendimento Ultra Rápido"),
        title: 'A Importância de um Atendimento Ultra Rápido',
        content: "Você deve estar se perguntando... O que essa pergunta ‘tem a ver’ com o meu atendimento, ou com o meu cliente de energia solar? Tudo... Entenda. Costumamos comparar um cliente de energia solar, com a duração do gás de um refrigerante depois de aberto. O refrigerante...",
        post: post21Data(),
        author: 3,
        comments: 23,
        date: "30 de Julho de 2021",
    },
    {
        id: 22,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/328-1thumb.png",
        slug: CreateSlug("5 Pontos Críticos de uma empresa de Energia Solar"),
        title: '5 Pontos Críticos de uma empresa de Energia Solar',
        content: "Aumentar as vendas, aumentar os resultados é o objetivo de toda e qualquer empresa. Mas como de fato, chegar lá? Não há fórmula mágica, é uma série de processos, de tarefas que devem se tornar cotidianas para que os resultados apareçam gradativamente...",
        post: post22Data(),
        author: 3,
        comments: 23,
        date: "04 de Outubro de 2021",
    },
    {
        id: 23,
        category: "Eventos",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/329-1thumb.png",
        slug: CreateSlug("GDPlace na InterSolar South America 2021"),
        title: 'GDPlace na InterSolar South America 2021',
        content: "O mercado de energia solar no Brasil está em plena expansão, após bater três recordes consecutivos em energia solar instalada, chegando na incrível marca em 2020 de 3.150MW. Neste ano, o Brasil já passou de 10 GW instalados, segundo a ABSOLAR...",
        post: post23Data(),
        author: 3,
        comments: 23,
        date: "21 de Outubro de 2021",
    },
    {
        id: 24,
        category: "Dicas",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "/assets/img/blog/330-1thumb.png",
        slug: CreateSlug("WhatsApp como uma poderosa ferramenta de venda na Solar"),
        title: 'WhatsApp como uma poderosa ferramenta de venda na Solar',
        content: "Segundo a pesquisa realizada pela Panorama Mobile Time/Opinion Box, 99% dos Brasileiros tem o Aplicativo instalado em seu smartphone. O que não chega a ser uma novidade. Mas como usar o WhatsApp como uma poderosa ferramenta de Venda para Energia Solar?...",
        post: post24Data(),
        author: 3,
        comments: 23,
        date: "18 de Novembro de 2021",
    },
]