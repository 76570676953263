function createSlug(str) {
  // Remover acentos e caracteres especiais, substituindo-os por seus equivalentes em letras simples
  const normalizedStr = str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  // Substituir espaços e caracteres não alfanuméricos por hífens
  const slug = normalizedStr
    .replace(/\s+/g, '-')         // Substituir espaços por hífens
    .replace(/[^\w-]+/g, '')      // Remover caracteres não alfanuméricos (exceto hífens)
    .replace(/--+/g, '-')         // Remover múltiplos hífens consecutivos
    .replace(/^-+|-+$/g, '');    // Remover hífens no início e no final

  return slug;
}

export const CreateSlug = (title) => {
    const slug = createSlug(title);
    return slug
}