// import { createStore } from "redux";
// import rootReducer from './reducer';

// export const store = createStore(rootReducer);

// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from './reducer'
import { createStore, applyMiddleware, compose } from 'redux'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middleware))
)

export { store }
