import React, { memo } from 'react'

// Data
import { ListData01 } from './ListsData'

// Libraries
import { m } from 'framer-motion';
import { PropTypes } from "prop-types";

const Lists = (props) => {
    return (
        <ul className={`${props.theme}${props.className ? ` ${props.className}` : ""}`} style={props.style ? props.style : {}}>
            {
                props.data.map((item, i) => {
                    return (
                        <m.li 
                            key={i}
                            {...{ ...props.animation, transition: { delay: (i+1) * props.animationDelay, initial: { opacity: 0, x: "100%" }, ease: [0.25, 1, 0.5, 1], duration: 1.2 } }}
                        >
                            {(item.icon && item.subcontent.length === 0) && <i className={item.icon}></i>}
                            {item.linkTitle && <a aria-label="list" href={item.link} >{item.linkTitle}</a>}
                            {item.title ?
                                <div className='block'>
                                    {item.title && <span className='block list-title'>{item.title}</span>}
                                    <span className='list-content'>{item.content}</span>
                                </div> : (
                                    <>
                                        {item.subcontent && item.subcontent.length > 0
                                        ?
                                            <>
                                                <div className='w-100'>
                                                    {item.icon && <i className={item.icon}></i>}

                                                    <span>{item.content}</span>

                                                    <div className='w-100'>
                                                        <ul className={`my-3 ${props.theme}${props.className ? ` ${props.className}` : ""}`}>
                                                            {item.subcontent.map((item, i2) => (
                                                                    <m.li key={i2}  {...{ ...props.animation, transition: { delay: (i+1) * props.animationDelay, initial: { opacity: 0, x: "100%" }, ease: [0.25, 1, 0.5, 1], duration: 1.2 } }}>
                                                                        {item.icon && <i className={item.icon}></i>}
                                                                        <span>{item.content}</span>
                                                                    </m.li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            <span dangerouslySetInnerHTML={{ __html: item.content }} />
                                        }
                                    </>
                                )}
                            {item.number && <span className='list-number'>{item.number}</span>}
                        </m.li>
                    )
                })
            }
        </ul>
    )
}

Lists.defaultProps = {
    data: ListData01,
    theme: "list-style-01",
    animationDelay: 0.1
}

Lists.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            content: PropTypes.string,
            link: PropTypes.string,
            icon: PropTypes.string,
            linkTitle: PropTypes.string,
            number: PropTypes.string,
            subcontent: PropTypes.array,
        })
    ),
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
    theme: PropTypes.string,
}

export default memo(Lists)