import axios from 'axios';
import { getPathApi } from './Utilities';

const getAuthData = () => {
    const user = process.env.REACT_APP_API_USERNAME
    const pass = process.env.REACT_APP_API_PASS

    return {
        username: user,
        password: pass
    }
}

export const getToken = async () => {
    const path = getPathApi()

    try {
        const urlRota = `${path}/AuthAPISite/getToken`;
        const authData = getAuthData();

        const response = await axios.post(urlRota, authData);

        return response;
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error; // Rejeita a promessa com o erro
    }
}


export const postApi = async (url, token, dados) => {
    try {
        const response = await axios.post(url, new URLSearchParams(dados), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
          },
        });
  
        return response.data
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
        console.log('Detalhes do erro:', error.response.data);
      }
}

export const getApiSite = async (url, token) => {
    // Configuração da solicitação
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Bearer ${token}` // Passa o token no cabeçalho de autorização
        }
    };

    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Erro na solicitação:', error);
            return error
        });
}

export const getApiExternal = async (url) => {
    const config = {
        method: 'GET',
        url: url
    }

    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Erro na solicitação:', error);
            return error
        });
}